<template>
<body class="IsCenter" style="margin-top: 60px; margin-left: 210px;">
    <div>
        <h1 style="margin-left: 30px; font-size: 50px; margin-bottom: 0px;">Support</h1>
        <div style="margin-left: 40px;">
            <div style="display: inline-block;">
                <label>Name:</label>
                <input type="Name" ref="name" v-model="name" placeholder="Richard"></input>
                <label>Email:</label>
                <input ref="email" v-model="email" placeholder="indert@ct.ai"></input>    
            </div>
            <div>
                <div>
                    <label>Subject:</label>
                    <div style="margin-left: -10px;">
                        <input style="padding-right: 60%;" ref="subject" v-model="subject" placeholder="Type your subject here..."></input>
                    </div>
                </div>
                <div>
                    <label>Message:</label>
                    <div style="margin-left: -10px;">
                        <textarea style="padding-bottom: 500px; width: 90%;" ref="message" v-model="msg" placeholder="Type your message here..."></textarea>
                    </div>
                </div>
            </div>
            <div>
                <button class="glow-on-hover" style="margin-bottom: 20px; width: 20%; height: 40px;" @click="handleEmailSend">Send Message</button>
            </div>
            <!-- Sucessfully sent message! We´ll answer you as soon as possible! -->
            <div style="color: green; margin-left: 10px; margin-top: -10px; margin-bottom: 20px;" ref="feed">{{ feedbackSent }}</div>
            <div>
                <strong style="margin-left: 10px;">Or call / send us a message at +351 933723211</strong>
            </div>
        </div>
    </div>
</body>
</template>

<script>
export default {
    name: 'support',
    data(){
        return {
            name: '',
            email: '',
            subject: '',
            msg: '',
            feedbackSent: '',
            code: ''
        }
    },
    methods: {
        handleEmailSend(){
            fetch('https://indertct.me/api/emailPost', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: this.name, Email: this.email, Subject: this.subject, Message: this.msg})})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 0){
                        this.feedbackSent = 'Successfully sent email';
                        this.name = '';
                        this.msg = '';
                        this.email = '';
                        this.subject = '';
                    } else if (data.code == 2){
                        console.log(data.msg);
                        this.$refs.feed.style.color = 'red';
                        this.feedbackSent = 'Something went wrong';
                    }
                })
                .catch(err => console.log(err))
        }
    },
    mounted(){
        document.title = 'Support';
    }
}
</script>

<style scoped>
label{
    font-weight: bold;
    font-size: 20px;
    margin: 10px;
    margin-left: 0;
}
input{
    margin: 10px;
    border: 2px solid black;
    border-radius: 10px;
}
textarea{
    margin: 10px;
    border: 2px solid black;
    border-radius: 10px;
}
</style>