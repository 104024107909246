<template>
<body class="IsCenter">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
    <div>
        <h1 style="padding-left: 30px; font-size: 50px;">Models</h1>
        <div v-show="privmodels">
            <h1>Private Models</h1>
            <table class="gridModels">
                <tr class="rowModels2">
                    <th class="index">Crypto</th>
                    <th class="index">Time</th>
                    <th class="index">Type</th>
                    <th class="index">Certainty</th>
                    <th class="index">Buy or Sell</th>
                    <th class="index">Take Off</th>
                </tr>
                <tr class="rowModels" v-for="dataPi in privmodels" v-show="dataPi.show">
                    <td class="data">{{ dataPi.crypto }}</td>
                    <td class="data">{{ dataPi.hour }}</td>
                    <td class="data">{{ dataPi.type }}</td>
                    <td class="data">{{ dataPi.pred * 100 }}%</td>
                    <td class="data2">
                        <div :class="{green: dataPi.buy, red: !dataPi.buy}" class="circle"></div>
                    {{ dataPi.label }}
                    </td>
                    <td style="width: 7%;">
                        <i class="fa-regular fa-circle-xmark remove-icon" style="font-size: 70px; cursor: pointer; margin-left: 30px;" @click="handletakeofffromprivs(dataPi)"></i>
                    </td>
                </tr>
            </table>
        </div>
        <button class="glow-on-hover" style="margin: 30px;" @click="openPriv">Add Private Model</button>
        <div v-show="models">
            <h1>Public Models</h1>
            <table class="gridModels" id="table">
                <tr class="rowModels2">
                    <th class="index">Crypto</th>
                    <th class="index">Time</th>
                    <th class="index">Type</th>
                    <th class="index">Certainty</th>
                    <th class="index">Buy or Sell</th>
                    <th class="index">Favorites</th>
                </tr>
                <tr class="rowModels" v-for="dataP in modelsFav" :id="dataP.index">
                    <td class="data">{{ dataP.crypto }}</td>
                    <td class="data">{{ dataP.hour }}</td>
                    <td class="data">{{ dataP.type }}</td>
                    <td class="data">{{ dataP.pred * 100}}%</td>
                    <td class="data2">
                        <div :class="{green: dataP.buy, red: !dataP.buy}" class="circle"></div>
                    {{ dataP.label }}
                    </td>
                    <td style="width: 3%;">
                        <i class="fa-star remove-icon" :class="{'fa-regular': !dataP.isFav, 'fa-solid': dataP.isFav}" style="color: #FFD43B; font-size: 40px; cursor: pointer; margin: auto; margin-left: 15px;" @click="hanldeChangeFav(dataP)"></i>
                    </td>
                </tr>
                <tr class="rowModels" v-for="dataP in modelsNotFav" :id="dataP.index">
                    <td class="data">{{ dataP.crypto }}</td>
                    <td class="data">{{ dataP.hour }}</td>
                    <td class="data">{{ dataP.type }}</td>
                    <td class="data">{{ dataP.pred * 100}}%</td>
                    <td class="data2">
                        <div :class="{green: dataP.buy, red: !dataP.buy}" class="circle"></div>
                    {{ dataP.label }}
                    </td>
                    <td style="width: 3%;">
                        <i class="fa-star remove-icon" :class="{'fa-regular': !dataP.isFav, 'fa-solid': dataP.isFav}" style="color: #FFD43B; font-size: 40px; cursor: pointer; margin: auto; margin-left: 15px;" @click="hanldeChangeFav(dataP)"></i>
                    </td>
                </tr>
            </table>
            <div style="margin: auto; padding: 10px; display: grid; place-items: center;">
                <button class="glow-on-hover" style="width: 50%; padding: 10px; height: 50px; align-text: center; border-radius: 20px;" @click="openReq">Request New Public Model</button>
            </div>
        </div>
    </div>
</body>
<newPublicModelRequest :show="showReq" @close="closeReq"/>
<modelsPriv :show="showPrivs" @close="closePriv" @newCR="fetchData" :watching="change"/>
</template>

<script>
import modelsPriv from '../components/modelPrivs.vue'
import newPublicModelRequest from '../components/newPublicModel.vue'
export default {
    name: 'Models',
    data(){
        return {
            models: [],
            maxfavindex: '0',
            favoritescsv: '',
            showReq: false,
            privmodels: [],
            showPrivs: false,
            maxN: 100,
            change: 0,
            modelsFav: [],
            modelsNotFav: []
        }
    },
    components: { newPublicModelRequest, modelsPriv },
    methods: {
        hanldeChangeFav(dataPiece){
            dataPiece.isFav = !dataPiece.isFav;
            if (dataPiece.isFav){
                this.modelsNotFav = this.modelsNotFav.filter(element => element.index != dataPiece.index);
                this.modelsFav.push(dataPiece);
            } else {
                this.modelsFav = this.modelsFav.filter(element => element.index != dataPiece.index);
                this.modelsNotFav.push(dataPiece);
            }
            var favorites = '';
            this.modelsFav.forEach(element => {
                favorites += element.id + ','
            })
            fetch('https://indertct.me/api/modelsPost', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({modelsW: favorites.slice(0, -1)
            })})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 2){
                        console.log(data.msg)
                    }
                })
                .catch(err => console.log(err))
        },
        makegoforward(ref){
            document.getElementById('table').insertBefore(document.getElementById(ref), document.getElementById('0'));
        },
        fU(){
            console.log("at least executed")
            this.$forceUpdate(); 
        },
        makegobacwards(ref){
            document.getElementById('table').insertBefore(document.getElementById(ref), document.getElementById(this.maxN.toString()));
        },
        makefetch(){
            this.change += 1;
        },
        closeReq(){
            this.showReq = false;
        },
        openReq(){
            this.showReq = true;
        },
        closePriv(){
            this.showPrivs = false;
        },
        openPriv(){
            this.showPrivs = true;
        },
        async handletakeofffromprivs(dataPiece){
            dataPiece.show = false;
            this.privmodels.filter(element => {
                return element != dataPiece;
            });
            var csv = '';
            this.privmodels.forEach(element => {
                csv += element.id + ',';
            });
            csv = csv.slice(0, -2);
            await fetch('https://indertct.me/api/changePrivs', {method: 'POST',  headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({modelprivcsv: csv})})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 2){
                        console.log(data.msg);
                    }
                })
                .catch(err => console.log(err))
            this.makefetch();
        },
        fetchData(){
            fetch('https://indertct.me/api/getModels', {method: 'POST',  headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 0){
                        this.models = data.data;
                        this.favoritescsv = data.favorites;
                        this.privmodels = data.privs;
                        this.models.forEach(element => {
                        if (element.isFav){
                            this.modelsFav.push(element);
                        } else {
                            this.modelsNotFav.push(element);
                        }
                        console.log(this.modelsFav);
                        console.log(this.modelsNotFav);
                    });
                    } else {
                        console.log(data.msg);
                    }
                })
                .catch(err => console.log(err))
        }
    },
    computed: {
    },
    mounted(){
        document.title = 'Models';
        this.fetchData();
    }
}
</script>

<style>
.gridModels {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    text-align: left;
}

.rowModels2 {
    background-color: #000000;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}

.index, .data {
    padding: 12px 15px;
    border: 1px solid #dddddd;
}

.rowModels:nth-child(even) {
    background-color: #f3f3f3;
}

.rowModels:hover {
    background-color: #f1f1f1;
}

.data2 {
    text-align: center;
}

.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 auto;
}

.green {
    background-color: #28a745;
}

.red {
    background-color: #dc3545;
}

.remove-icon {
    font-size: 20px;
    cursor: pointer;
    color: #dc3545;
    transition: transform 0.2s;
}

.remove-icon:hover {
    transform: scale(1.33);
}

body.IsCenter {
  margin-top: 70px;
  margin-left: 230px;
}
div.rowModels{
    display: flex;
}
div.gridModels{
    display: grid;
}
</style>