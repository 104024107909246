<template>
<body id="navigBody">
    <div class="top-bar" @mouseleave="closethat">
        <div class="search-container">
            <input type="text" placeholder="Search..." ref="searchbox" v-on:keyup.enter="EmitSearch" v-model="search">
        </div>
        <div class="user-account">
            <div>
                <img v-bind:src="userImage">
            </div>
            <div class="dropdown">
                <div class="dropdown-arrow" @mouseover="openthat">&#9662;</div>
                <div class="dropdown-content" @mouseleave="closethat" ref="userA">
                    <a @click="Account">Account</a>
                    <a @click="Settings">Settings</a>
                    <!-- <a @click="Pay">Payment Settings</a> -->
                    <a @click="API">API</a>
                    <a @click="logout">Logout</a>
                </div>
            </div>
        </div>
    </div>
    <nav class="navbar">
        <div class="navbar-container">
            <div class="logo">
                <img v-bind:src="logo">
                <h3>INDERT CT</h3>
            </div>
            <ul class="nav-links">
                <li>
                    <a @click="Dashboard" :class="{ clicked: dashboard}">
                        <img v-bind:src="homeImg">
                        <span>Dashboard</span>
                    </a>
                </li>
                <li>
                    <a @click="CryptoSignals" :class="{ clicked: crypto_signals}">
                        <img :src="buysell">
                        <span>Trading Signals</span>
                    </a>
                </li>
                <li>
                    <a @click="Models" :class="{ clicked: models}">
                        <img v-bind:src="robot">
                        <span>Models</span>
                    </a>
                </li>
                <li>
                    <a @click="API" :class="{ clicked: userapi}">
                        <img v-bind:src="api">
                        <span>API</span>
                    </a>
                </li>
                <li>
                    <a @click="OKX" :class="{ clicked: okx_integ}">
                        <img v-bind:src="coin">
                        <span>Binance Integration</span>
                    </a>
                </li>
                <li>
                    <a @click="Account" :class="{ clicked: account_tf}">
                        <img v-bind:src="profile">
                        <span>Account</span>
                    </a>
                </li>
                <li>
                    <a @click="Support" :class="{ clicked: support_tf}">
                        <img v-bind:src="contact">
                        <span>Support</span>
                    </a>
                </li>
                <li>
                    <a @click="newHere" :class="{ clicked: new_here}">
                        <img v-bind:src="question">
                        <span>New Here?</span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</body>
</template>

<script>
export default {
    name: 'nav',
    data(){
        return {
            namesdontneedauth: ['/thanks', '/documentation', '/login', '/signup'],
            homeImg: require('../assets/images/round.png'),
            logo: require('../assets/images/F.jpg'),
            about: require('../assets/images/selling.png'),
            buysell: require('../assets/images/execution.png'),
            robot: require('../assets/images/robot.png'),
            api: require('../assets/images/api.png'),
            coin: require('../assets/images/coin.png'),
            profile: require('../assets/images/profile.png'),
            contact: require('../assets/images/contact-us.png'),
            question: require('../assets/images/question.png'),
            usr: require('../assets/images/user.png'),
            search: ''
        }
    },
    props: ['userImg', 'changing'],
    computed: {
        userImage(){
            if(this.userImg){
                if(this.userImg === 'self'){
                    return 'https://indertct.me/api/image_profile_get/'+localStorage.getItem('Username');
                } else {
                    return require('@/assets/images/'+this.userImg+'.png');
                }
            } else {
                return require('@/assets/images/user.png');
            }
        },
        routerPath(){
            return this.$route.name;
        },
        dashboard(){
            if(this.$route.name == 'home'){
                return true
            } else {
                return false
            }
        },
        crypto_signals(){
            if(this.$route.name == 'signals'){
                return true
            } else {
                return false
            }
        },
        models(){
            if(this.$route.name == 'models'){
                return true
            } else {
                return false
            }
        },
        userapi(){
            if(this.$route.name == 'api'){
                return true
            } else {
                return false
            }
        },
        okx_integ(){
            if(this.$route.name == 'okx'){
                return true
            } else {
                return false
            }
        },
        account_tf(){
            if(this.$route.name == 'account'){
                return true
            } else {
                return false
            }
        },
        support_tf(){
            if(this.$route.name == 'support'){
                return true
            } else {
                return false
            }
        },
        new_here(){
            if(this.$route.name == 'newHere'){
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        Dashboard(){
            this.$router.push({ name: 'home'})
        },
        CryptoSignals(){
            this.$router.push({ name: 'signals'})
        },
        Models(){
            this.$router.push({ name: 'models'})
        },
        API(){
            this.$router.push({ name: 'api'})
        },
        OKX(){
            this.$router.push({ name: 'okx'})
        },
        Account(){
            this.$router.push({ name: 'account'})
        },
        Support(){
            this.$router.push({name: 'support'})
        },
        newHere(){
            this.$router.push({name: 'newHere'})
        },
        closethat(){
            this.$refs.userA.style.display = 'none';
        },
        openthat(){
            this.$refs.userA.style.display = 'block';
        },
        logout(){
            localStorage.setItem('logged', '');
            localStorage.setItem('Username', '');
            localStorage.setItem('Password', '');
            localStorage.setItem('Email', '');
            this.$router.push({name: 'login'});
            location.reload();
        },
        Settings(){
            this.$router.push({ name: 'settings'})
        },
        EmitSearch(){
            window.open('https://www.coindesk.com/search?s='+this.search, '_blank');
            this.search = '';
        }
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
}

.navbar {
    background-color: #000;
    width: 200px;
    height: 1000%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.navbar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.logo img {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
}

.logo{
    display: flex;
    align-items: center;
    border-bottom: solid 2px white;
    margin-bottom: 15px;
}

.nav-links {
    list-style: none;
    width: 100%;
}

.nav-links li {
    margin-bottom: 20px;
}

.nav-links li a:hover{
    cursor: pointer;
}

.logo h3 {
    color: #fff;
    font-size: 16px;
    margin-left: 10px;
}

.nav-links a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    width: 100%;
}

.nav-links a img {
    height: 24px;
    margin-right: 10px;
}

.nav-links a:hover {
    background-color: #555;
    border-radius: 4px;
}

.clicked {
    background-color: #555;
    border-radius: 4px;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.search-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

.search-container input[type="text"] {
    width: 50%;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.user-account {
    display: flex;
    align-items: center;
    position: relative;
}

.user-account img {
    height: 30px;
    border-radius: 50%;
}

.dropdown {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-arrow {
    margin-left: 8px;
    font-size: 16px;
    color: #fff;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    z-index: 1001;
}

.dropdown-content a {
    display: block;
    padding: 10px;
    color: #333;
    text-decoration: none;
    font-size: 14px;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

</style>