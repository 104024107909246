<template>
  <NAVIG @search="searching" :userImg="userImage" v-show="shownav" :changing="change" ref="nav" />
  <router-view @newImg="newIM" @fetch="fetchImg" />
</template>

<script>
import NAVIG from './components/nav.vue'

export default {
  name: 'MainApp',
  components: { NAVIG },
  computed: {
    shownav(){
      var nametemp = this.$route.name
      if(!this.allUrlNames.includes(nametemp)){
        if (nametemp=='login' || nametemp=='signup' || nametemp=='login2' || nametemp=='affsignup'){
          document.getElementById('mainBody').style['background-color'] = 'black';
        } else if (nametemp == 'affiliates'){
          
        } else {
          document.getElementById('mainBody').style['background-color'] = 'white';
        }
        return false;
      } else {
        document.getElementById('mainBody').style['background-color'] = 'white';
        return true;
      }
    }
  },
  data(){
    return {
      change: 0,
      userImage: '',
      allUrlNames: ['home', 'account', 'support', 'models', 'signals', 'okx', 'api', 'settings', 'newHere'],
      allurlRl: ['home', 'account', 'support', 'models', 'signals', 'okx', 'api', 'settings', 'newHere', 'thanks', 'documentation', 'login', 'signup'],
      namesdontneedauth: ['/thanks', '/documentation', '/login', '/signup']
    }
  },
  methods: {
    newIM(data){
      this.userImage = data;
      if (data == 'self'){
        console.log("Should console log 'aaaa'")
        this.change++;
      }
    },
    async fetchImg(){
      console.log("App fetching image")
      try {
            const response = await fetch('https://indertct.me/api/imgUser', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            this.userImage = data.img;
            } catch (error) {
            console.error('Error:', error);
            }
    },
    searching(searchTopic){
      window.open('https://google.com/serch?q='+searchTopic);
    }
  },
  created(){
    if(!localStorage.getItem('logged') || !localStorage.getItem('Username') || !localStorage.getItem('Password')){

    } else {
      this.fetchImg();
      this.$router.push({ name: 'home' })
    }
  },

}
</script>

<style>
body.IsCenter{
  margin-top: 60px;
  margin-left:200px;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
