<template>
<div style="margin-top: 20px;">
    <h1 style="margin-left: 0">{{ obj.title }}</h1>
    <div style="margin-left: 20px;">
        <div style="width: 80%; margin-top: 10px;">
            <span>{{ obj.msg }}</span>
        </div>
        <div v-if="obj.href != ''">
            <h2>Youtube Tutorial</h2>
            <div class="video-wrapper">
                <vue-plyr>
                    <div 
                    data-plyr-provider="youtube" 
                    :data-plyr-embed-id="obj.href"
                    ></div>
                </vue-plyr>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['obj'],
    name: '',
    data(){
        return {
        }
    },
    methods: {

    },
    computed: {
    }
}
</script>

<style>
.video-wrapper{
    width: 40%;
}
</style>