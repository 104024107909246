<template>
<okx />
</template>

<script>
import okx from '../components/okxxomp.vue'
export default {
    components: { okx },
    name: '',
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>