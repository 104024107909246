<template>
<div v-if="show">
    <div v-show="showing">
        <div class="backdrop" @click.self="close">
            <div v-if="crList" class="modal">
                <div class="enclose" v-for="cr in crList" :key="cr" v-if="!crlist.includes(cr)">
                    <div class="crypto">{{ cr }}</div>
                    <div class="add" @click="handleNewCr(cr)">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="10px" width="10px" version="1.1" id="Capa_1" viewBox="0 0 60.364 60.364" xml:space="preserve" style="margin-top: 7px; margin-left: 7px;">
                            <g>
                                <path d="M54.454,23.18l-18.609-0.002L35.844,5.91C35.845,2.646,33.198,0,29.934,0c-3.263,0-5.909,2.646-5.909,5.91v17.269   L5.91,23.178C2.646,23.179,0,25.825,0,29.088c0.002,3.264,2.646,5.909,5.91,5.909h18.115v19.457c0,3.267,2.646,5.91,5.91,5.91   c3.264,0,5.909-2.646,5.91-5.908V34.997h18.611c3.262,0,5.908-2.645,5.908-5.907C60.367,25.824,57.718,23.178,54.454,23.18z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="request" @click="openR">
                    <strong>Request new Crypto</strong>
                </div>
            </div>
        </div>
    </div>
    <div>
        <RequestNew :show="showsn" @close="closeR" />
    </div>
</div>
</template>

<script>
import RequestNew from './RequestCrypto.vue'

export default {
    props: ['show', 'crlist'],
    components: { RequestNew },
    data(){
        return {
            crList: '',
            showsn: false,
            showing: true
        }
    },
    mounted(){
        fetch('https://indertct.me/api/getGlobal', {method: 'GET', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}})
            .then(res => res.json())
            .then(data => {
                if(data.code == "0"){
                    this.crList = data.cryptosGlobal;
                }
            })
    },
    methods: {
        close(){
            this.$emit('close')
        },
        handleNewCr(cr){
            console.log('New Cryptocurrency');
            this.$emit('new', cr);
            this.close();
        },
        openR(){
            this.showsn = true;
            this.showing = false;
        },
        closeR(){
            this.showsn = false;
            this.showing = true;
        }
    },
    computed: {
        chunkedCrList() {
            const chunkSize = 3;
            const result = [];
            for (let i = 0; i < this.crList.length; i += chunkSize) {
            result.push(this.crList.slice(i, i + chunkSize));
            }
            return result;
        }
    }
}
</script>

<style>
/* Overall backdrop styling */
.backdrop {
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.7); /* Slightly darker overlay */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal styling with scroll */
.modal {
    padding: 30px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add subtle shadow for depth */
    max-width: 600px; /* Constrain max width */
    width: 100%;
    max-height: 80vh; /* Limit the height to 80% of the viewport */
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
    overflow-x: hidden;
}

/* Crypto row styling to ensure 3 items per row */
.crypto-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    flex-wrap: wrap; /* Allows wrapping in case of overflow */
}

/* Enclose 3 items per line */
.enclose {
    margin-top: 10px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 10px; /* Add space between items */
    max-width: 180px; /* Limit item width to fit 3 in a row */
    transition: background 0.3s ease; /* Smooth transition */
}

.enclose:last-child {
    margin-right: 0; /* No margin on the last item in the row */
}

.enclose:hover {
    background: #e0e0e0; /* Lighter hover effect */
}

/* Crypto text */
.crypto {
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    font-weight: 500;
    color: #333;
}

/* "+" button */
.add {
    background-color: #ddd;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.add:hover {
    background-color: #888;
    color: white;
}

/* Request new Crypto button styling */
.request {
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 10px;
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0; /* Centered and with some top margin */
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: background 0.3s ease;
}

.request:hover {
    background-color: #888;
    color: white;
}
</style>