<template>
    <support />
</template>

<script>
import support from '../components/support.vue'

export default {
    name: 'supportView',
    data(){
        return {

        }
    },
    components: { support },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>