<template>
<newHere />
</template>

<script>
import newHere from '../components/newHere.vue'
export default {
    components: { newHere },
    name: '',
    mounted(){
        document.title = 'New Here?';
    },
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>