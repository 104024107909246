<template>
<div class="backdrop" @click.self="close" v-if="show">
    <div class="modal" style="width: 50%; height: 12%;">
        <div style="display: grid; place-items: center; width: auto; height: auto;">
            <h1 style="color: green;">Thanks a lot for choosing us!</h1><i class="fa-solid fa-heart" style="color: #e21212; width: 30px; height: 30px;"></i>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['show'],
    name: '',
    data(){
        return {
        }
    },
    methods: {
        close(){
            this.$emit('close');
        }
    },
    computed: {
        
    }
}
</script>

<style>

</style>