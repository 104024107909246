<template>
<div style="width: 100%; height: auto; background-color: #eee; border-radius: 10px; padding: 20px; margin-bottom: 30px;">
    <h3>{{ name }}</h3>
    <div>
        <h1>{{ n }}</h1>
    </div>
    <div>
        <span>{{ msg }}</span>
    </div>
    <div v-if="note" style="margin-top: 30px;">
        <span style="font-size: 15px;">{{ note }}</span>
    </div>
    <slot/>
</div>
</template>

<script>
export default {
    name: '',
    props: ['name', 'msg', 'n', 'note'],
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>