<template>
<div v-if="show">
    <div v-if="showing">
        <div class="backdrop" @click.self="close">
            <div class="modal">
                <div>
                    <label for="pass" style="font-weight:bold;">Please enter your old password:</label>
                    <div style="margin-top: 10px;">
                        <input id="pass" v-model="passphrase"></input>
                    </div>
                </div>
                <div>
                    <label for="newpass" style="font-weight:bold;">New Password:</label>
                    <div styel="margin-top: 10px;" id="newpass">
                        <input ref="newpass" v-model="newPass"></input>
                    </div>
                </div>
                    <button class="glow-on-hover" @click="handleProceed">Proceed</button>
                <div class="feetback" res="feed">{{ feedback }}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    data(){
        return {
            passphrase: '',
            showing: true,
            feedback: '',
            newPass: ''
        }
    },
    props: ['show'],
    methods: {
        close(){
            this.$emit('close');
        },
        handleProceed(){
            fetch('https://indertct.me/api/passPost', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Password: this.passphrase})})
                .then(res => res.json())
                .then(data => {
                    if(data.code == 0){
                        this.showing = false;
                    } else if (data.code == 1){
                        this.feedback = 'Password doesn´t match';
                    } else if (data.code == 2){
                        this.feedback = 'Something went wrong';
                        console.log(data.msg);
                    } else if (data.code == 3){
                        this.feedback = 'Invalid account';
                    } else if (data.code == 4){
                        this.feedback = 'Account'
                    }
                })
        }
    }
}
</script>

<style>
.feetback{
    color: red;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal #pass{
    border: 1px solid black;
    border-radius: 10px;
    height: 30px;
    width: 150px;
    margin: 10px;
    margin-left: 0;
}

.glow-on-hover {
        width: 130px;
        margin-left: 5px;
        margin-top: 10px;
        height: 30px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 20px;
        align: center;
    }
    
    .glow-on-hover:before {
        content: '';
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        border-radius: 20px;
    }
    
    .glow-on-hover:active {
        color: #000
    }

    @keyframes glowing {
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }
    
    .glow-on-hover:active:after {
        background: transparent;
    }
    
    .glow-on-hover:hover:before {
        opacity: 1;
    }
    
    .glow-on-hover:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        left: 0;
        top: 0;
        border-radius: 20px;
    }

.modal{
        width: 310px;
        padding: 20px;
        margin: 200px auto;
        background: white;
        border-radius: 30px;
}
</style>