<template>
  <div class="not-found">
    <div class="error-message">
      <h1>404 Error: Page Not Found</h1>
    </div>
    <div class="separator"></div>
    <router-link to="/" class="home-link" :to="{ name: 'home' }">Go Back Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  created(){
    document.title = '404 not found';
  }
}
</script>

<style scoped>
.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f8f8;
  color: #333;
}

.error-message h1 {
  font-weight: bold;
  font-size: 100px;
  margin: 0;
}

.separator {
  margin-top: 30px;
  width: 75%;
  background-color: black;
  height: 10px;
}

.home-link {
  margin-top: 20px;
  font-size: 20px;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}
</style>