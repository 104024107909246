<template>
  <body class="IsCenter">
    <div class="mainDiv">
      <div class="comhelper">
        <h1 class="acc">Account</h1>
      </div>
      <avatar :src="imageeMain" @file="NewPhoto" @fetch="fetchapp" :upload="upload_var" @true="upload_true" @false="upload_false"  @isNowSelf="getSelf" />
      <div class="divider" />
      <h1 class="Name" style="margin-left: -25px;" v-show="name && email">
      {{ name }} - {{ email }}
      </h1>
      <h1 v-show="!(name && email)">
      Loading...
      </h1>
      <phone />
      <div class="themboth">
      <div class="image-grid">
        <div class="row">
        <!-- First Row -->
        <div class="image-cell" @click="newImg('ai(1)')">
          <img v-bind:src="images[0]">
        </div>
        <div class="image-cell" @click="newImg('ai')">
          <img v-bind:src="images[1]">
        </div>
        <div class="image-cell" @click="newImg('artificial-intelligence(1)')">
          <img v-bind:src="images[2]">
        </div>
        <div class="image-cell" @click="newImg('artificial-intelligence(2)')">
          <img v-bind:src="images[3]">
        </div>
        <div class="image-cell" @click="newImg('artificial-intelligence')">
          <img v-bind:src="images[4]">
        </div>
        </div>
        <!-- Second Row -->
        <div class="row">
        <div class="image-cell" @click="newImg('avatar(1)')">
          <img v-bind:src="images[5]">
        </div>
        <div class="image-cell" @click="newImg('avatar')">
          <img v-bind:src="images[6]">
        </div>
        <div class="image-cell" @click="newImg('bitcoin(1)')">
          <img v-bind:src="images[7]">
        </div>
        <div class="image-cell" @click="newImg('bitcoin-analysis')">
          <img v-bind:src="images[8]">
        </div>
        <div class="image-cell" @click="newImg('businessman(1)')">
          <img v-bind:src="images[9]">
        </div>
        </div>
        <!-- Third Row -->
        <div class="row">
        <div class="image-cell" @click="newImg('businessman(2)')">
          <img v-bind:src="images[10]">
        </div>
        <div class="image-cell" @click="newImg('businessman')">
          <img v-bind:src="images[11]">
        </div>
        <div class="image-cell" @click="newImg('chatbot')">
          <img v-bind:src="images[12]">
        </div>
        <div class="image-cell" @click="newImg('chip')">
          <img v-bind:src="images[13]">
        </div>
        <div class="image-cell" @click="newImg('client')">
          <img v-bind:src="images[14]">
        </div>
        </div>
        <!-- Fourth Row -->
        <div class="row">
        <div class="image-cell" @click="newImg('edit')">
          <img v-bind:src="images[15]">
        </div>
        <div class="image-cell" @click="newImg('illustrator')">
          <img v-bind:src="images[16]">
        </div>
        <div class="image-cell" @click="newImg('lottery')">
          <img v-bind:src="images[17]">
        </div>
        <div class="image-cell" @click="newImg('maintenance')">
          <img v-bind:src="images[18]">
        </div>
        <div class="image-cell" @click="newImg('money(1)')">
          <img v-bind:src="images[19]">
        </div>
        </div>
        <!-- Fifth Row -->
        <div class="row">
        <div class="image-cell" @click="newImg('profile(1)')">
          <img v-bind:src="images[20]">
        </div>
        <div class="image-cell" @click="newImg('profile(2)')">
          <img v-bind:src="images[21]">
        </div>
        <div class="image-cell" @click="newImg('robot(1)')">
          <img v-bind:src="images[22]">
        </div>
        <div class="image-cell" @click="newImg('user(1)')">
          <img v-bind:src="images[23]">
        </div>
        <div class="image-cell" @click="newImg('wallet')">
          <img v-bind:src="images[24]">
        </div>
        </div>
      </div>
      <div class="dividerRL">
        <div>
          Account valid untill: {{ VU }}
        </div>
        <div>
          Account Level: {{ AL }}
        </div>
      </div>
      <button class="glow-on-hover specialbutt" style="boroder-radius:100px;" @click="handleOKXopen">Change or see Binance apikey data</button>
      </div>
    </div>
    <div> 
      <h1>Username & Email</h1>
      <div style="margin-left: 20px; margin-top: 20px;">
        <div class="inpbox">
          <label for="usernamechange">Username:</label>
          <input id="usernamechange" ref="usernameChange" class="ueinp" ></input>
        </div>
        <div ref="invalidusername" style="margin-top: 15px; color: red;"></div>
        <div class="inpbox">
          <label for="emailchange">Email:</label>
          <input id="emailchange" ref="emailChange" class="ueinp"></input>
        </div>
        <div ref="invalidemail" style="margin-top: 15px; color: red;"></div>
        <button class="glow-on-hover" @click="handleusernameemailchange" style="width: 200px; margin-top: 20px;">Change Username / E-mail</button>
        <div ref="success" style="margin-top: 15px; color: green;"></div>
      </div>
      <h1 style="margin-top: 10px;">Change Password</h1>
      <div style="margin-left: 20px; margin-top: 0;">
        <div>
          <label style="font-weight: bold">Old Password:</label>
          <input ref="oldpass" style="width: 200px; height: 20px; border-radius: 10px; border: 1px solid black; margin-left: 17px;"></input>
        </div>
        <div style="margin-top: 15px;">
          <label style="font-weight: bold">New Password:</label>
          <input ref="newpass" style="width: 200px; height: 20px; border-radius: 10px; border: 1px solid black; margin-left: 17px;"></input>
        </div>
      </div>
      <div>
        <button ref="activateChangePassword" @click="handlePassChange" class="glow-on-hover" style="height: 30px; width: 200px; display:grid; place-items: center; margin-left: 20px; margin-top: 30px;">Change Password</button>
      </div>
      <div ref="feedback" style="margin-left: 20px; margin-top: 10px;"></div>
    </div>
    <div style="margin-top: 30px;">
      <h1>Indert CT Apikey</h1>
      <div style="display: flex; align-items: center;">
        <div style="margin-left: 20px;">Your apikey:</div>
        <div style="margin-left: 10px; border-radius: 0; border: 1px solid black; width: auto; padding: 5px; height: 20px;">{{ apikeyNR }}</div>
        <button class="fa fa-eye" style="height: 32px; width: 32px;" @click="handleBlind" ref="blindbutt"></button>
        <button class="fa fa-copy" style="height: 32px; width: 32px;" @click="copyApikey"></button>
      </div>
      <div style="margin: 10px; color: green; margin-left: 20px; margin-top: 20px;" ref="faunt"></div>
    </div>
    <div style="margin-bottom: 100px;"></div>
  </body>
  <div>
    <askPassphrase :show="showpass" @close="handleOKXclose" />
  </div>
</template>

<script>
import avatar from './profile_image.vue'
import askPassphrase from './askPassphrase.vue'
import passFpass from './passFpass.vue'
import phone from './phone_.vue'

export default {
  data() {
    return {
        images: [
            require('../assets/images/ai(1).png'),
            require('../assets/images/ai.png'),
            require('../assets/images/artificial-intelligence(1).png'),
            require('../assets/images/artificial-intelligence(2).png'),
            require('../assets/images/artificial-intelligence.png'),
            require('../assets/images/avatar(1).png'),
            require('../assets/images/avatar.png'),
            require('../assets/images/bitcoin(1).png'),
            require('../assets/images/bitcoin-analysis.png'),
            require('../assets/images/businessman(1).png'),
            require('../assets/images/businessman(2).png'),
            require('../assets/images/businessman.png'),
            require('../assets/images/chatbot.png'),
            require('../assets/images/chip.png'),
            require('../assets/images/client.png'),
            require('../assets/images/edit.png'),
            require('../assets/images/illustrator.png'),
            require('../assets/images/lottery.png'),
            require('../assets/images/maintenance.png'),
            require('../assets/images/money(1).png'),
            require('../assets/images/profile(1).png'),
            require('../assets/images/profile(2).png'),
            require('../assets/images/robot(1).png'),
            require('../assets/images/user(1).png'),
            require('../assets/images/wallet.png')
        ],
      userImg: '',
      name: '',
      email: '',
      CC: '',
      AL: '',
      VU: '',
      showpass: false,
      names: '',
      showpassFpasss: false,
      apikey: '',
      apikeyNR: '',
      apikeyDots: '',
      isDots: false,
      upload_var: false
    };
  },
  components: {
    askPassphrase,
    phone,
    passFpass,
    avatar
  },
  async mounted() {
    
    document.title = 'Account';
    if(!localStorage.getItem('Email') || !localStorage.getItem('Username')){
    fetch('https://indertct.me/api/emUser', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username')})})
      .then(res => res.json())
      .then(data => {
        this.email = data.email;
        this.name = data.Name;
        this.$refs.emailChange.value = data.email;
        this.$refs.usernameChange.value = data.Name;
        localStorage.setItem('Username', this.name);
        localStorage.setItem('Email', this.email);
      })
      .catch(err => console.log(err))} else {
        this.email = localStorage.getItem('Email');
        this.name = localStorage.getItem('Username');
        this.$refs.emailChange.value = this.email;
        this.$refs.usernameChange.value = this.name;
      }
    fetch('https://indertct.me/api/dataAcc', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username')})})
      .then(res => res.json())
      .then(data => {
        this.VU = data.valid_until;
        this.AL = data.AL;
        if(!this.AL){
          this.AL = '0';
        }
      })
      try {
        const response = await fetch('https://indertct.me/api/imgUser', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.userImg = data.img;
        this.$emit('newImg', this.userImg);
        } catch (error) {
        console.error('Error:', error);
        }
      fetch('https://indertct.me/api/apikeyGet', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username')})})
        .then(res => res.json())
        .then(data => {
          this.apikey = data.apikey;
          this.apikeyNR = data.apikey;
          this.apikeyDots = '';

          for (var i = this.apikey.length; i > 0; i--) {
            this.apikeyDots += '.';
          }})
        .catch(err => console.log(err))
  },
  methods: {
    getSelf(){
      this.userImg = 'self';
      this.$emit('newImg', 'self');
    },
    upload_true(){
      this.upload_var = true;
    },
    upload_false(){
      this.upload_var = false;
    },
    fetchapp(){
      console.log("passed to app");
      this.$emit('fetch');
    },
    NewPhoto(data){
        
    },
    newImg(img) {
      this.userImg = img;
      this.$emit('newImg', img);
      this.upload_var = false;
      fetch('https://indertct.me/api/imgendpoint', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username'), Image: img})})
        .then(res => res.json())
        .catch(err => console.log(err))
    },
    handlepassFpassclose(){
      this.showpassFpasss = false;
    },
    handlePassChange(){
      fetch("https://indertct.me/api/passPost2", {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username'), OldPassword: this.$refs.oldpass.value, NewPassword: this.$refs.newpass.value})})
        .then(res => res.json())
        .then(data => {
          if (data.code == 0){
            this.$refs.feedback.style.color = 'green';
            this.$refs.feedback.innerHTML = 'Changed Password';
          } else if(data.code == 1){
            this.$refs.feedback.style.color = 'red';
            this.$refs.feedback.innerHTML = 'The password is not correct';
          } else {
            console.log(data)
          }
        })
        .catch(err => console.log(err))
    },
    handleOKXclose(){
      this.showpass = false;
    },
    copyApikey(){
      this.copyTextToClipboard(this.apikey);
      this.$refs.faunt.innerHTML = 'Copied it';
    },
    handleOKXopen(){
      this.showpass = true;
    },
    async seeName(name){
      let returns;
      await fetch('https://indertct.me/api/get_names', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: name})})
        .then(res => res.json())
        .then(data => returns = data.data)
        .catch(err => console.log(err))
      return returns
    },
    handleBlind(){
      if(this.isDots){
                this.isDots = false;
                this.$refs.blindbutt.classList.remove("fa-eye-slash")
                this.$refs.blindbutt.classList.add("fa-eye")
                this.apikeyNR = this.apikey;
            } else {
                this.isDots = true;
                this.$refs.blindbutt.classList.remove("fa-eye")
                this.$refs.blindbutt.classList.add("fa-eye-slash")
                this.apikeyNR = this.apikeyDots;
            }
    },
    handleusernameemailchange(){
      if (this.$refs.usernameChange.value.includes("@") || !this.seeName(this.$refs.usernameChange.value)){
        this.$refs.invalidusername.innerHTML = 'Invalid Username';
        if (!this.$refs.emailChange.value.includes("@")){
          this.$refs.invalidemail.innerHTML = 'Invalid e-mail';
        } else {
          this.$refs.invalidemail.innerHTML = '';
        }
      } else if (!this.$refs.emailChange.value.includes("@")){
        this.$refs.invalidemail.innerHTML = 'Invalid e-mail';
      } else {
      this.$refs.invalidusername.innerHTML = '';
      this.$refs.invalidemail.innerHTML = '';
      fetch('https://indertct.me/api/NEpost', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username'), NameNow: this.$refs.usernameChange.value, EmailNow: this.$refs.emailChange.value})})
        .then(res => res.json())
        .then(data => {
          if(data.code == 0){
            this.$refs.success.innerHTML = 'Changed successfully';
            localStorage.setItem('Email', this.$refs.emailChange.value);
            localStorage.setItem('Username', this.$refs.usernameChange.value);
            this.name = this.$refs.usernameChange.value;
            this.email = this.$refs.emailChange.value;
          } else if(data.code == 1){
            this.$refs.invalidusername.innerHTML = 'Username already taken';
            console.log(data.msg);
          } else {
            console.log(data.msg);
          }
        })
        .catch(err => console.log(err))
      }
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;
    
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
          return;
            }
        navigator.clipboard.writeText(text).then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
    }
  },
  computed: {
    imageeMain(){
      if(this.userImg){
                if(this.userImg === 'self'){
                    return 'https://indertct.me/api/image_profile_get/'+localStorage.getItem('Username');
                } else {
                    return require('@/assets/images/'+this.userImg+'.png');
                }
            } else {
                return require('@/assets/images/user.png');
            }
  }
}
}
</script>

<style>
div.inpbox{
  margin-top: 10px;
}

input.ueinp{
  border-radius: 10px;
  width: 200px;
  height: 20px;
  border: 1px solid black;
  margin-left: 15px;
}

.themboth {
  display: flex;
  flex-direction: column; /* Align items at the top */
  gap: 20px; /* Optional: Add some space between the image grid and the account info */
}

button.specialbutt{
  width: 300px;
  height: 75px;
  font-weight: bold;
  font-size: 18px;
}

.dividerRL div {
  font-size: 20px;
  margin: 10px;
  font-weight: bold;
}

body.IsCenter {
  margin-top: 70px;
  margin-left: 230px;
}

.comhelper h1 {
  padding: 18px;
  font-size: 50px;
}

.usrImg img {
  max-width: 250px;
  border-radius: 50%;
}

.image-grid {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 0; /* Ensure there is no gap between grid items */
  padding-left: 10px;
}

.image-cell:hover {
  background-color: #999;
  transition: background-color 0.25s;
}

.image-cell {
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex; /* Use flex to control image alignment */
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
}

.image-cell img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0;
  padding: 0;
}

.divider {
  /* Divider styling if needed */
}


</style>
