<template>
<sis />
</template>

<script>
import sis from '../components/sis.vue'

export default {
    name: '',
    data(){
        return {

        }
    },
    components: { sis },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>