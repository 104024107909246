<template>
<body class="IsCenter">
    <h1 style="font-size: 40px;">New Here?</h1>
    <div>
        <newherecomp :obj="obj1" />
        <div style="margin-top: 20px;">
        <h1 style="margin-left: 0">{{ obj2.title }}</h1>
        <div style="margin-left: 20px;">
            <div style="width: 80%; margin-top: 10px;">
                <span>{{ obj2.msg }}</span><a target="_blank" href="https://pypi.org/project/IndertCT/0.0.7/" style="margin-left: 5px; margin-right: 5px;">here</a><span>and</span><a target="_blank" href="https://github.com/IndertCT/IndertCT" style="margin-left: 5px;">here</a><span>. Api documentation </span><router-link :to="{ name: 'documentation' }">here</router-link><span style="margin-left: 5px; margin-right: 5px;">or</span><router-link :to="{ name: 'api' }">here</router-link>
            </div>
            <div v-if="obj2.href != ''">
                <h2>Youtube Tutorial</h2>
                <div class="video-wrapper">
                    <vue-plyr>
                        <div 
                        data-plyr-provider="youtube" 
                        :data-plyr-embed-id="obj2.href"
                        ></div>
                    </vue-plyr>
                </div>
            </div>
            </div>
        </div>
        <newherecomp :obj="obj3" />
        <newherecomp :obj="obj4" />
        <newherecomp :obj="obj5" />
    </div>
</body>
</template>

<script>
import newherecomp from './newherecomp.vue'

export default {
    components: { newherecomp },
    name: '',
    data(){
        return {
            obj1: {
                title: 'Different ways to use Indert CT',
                msg: 'There are three primary ways you can use Indert CT: \n\nFirst out, you can see our models´ predictions by going to the Trading Signals tab, in which you´ll see all public signals by cryptocurrency and time and the private signals of the private models you can manage by going to the models tab, which is the second tab you can go to to see the models predictions.\nSecondly, you can Integrate you binance account with Indert CT and let us do the trading for you, but you will have to give us your apikey and secret key (an apikey without withdrawl permissions, obviously). We´ll keep your info safe with encryption, but if you don´t feel safe to give us that info there´s allways the third way of using Indert CT, which is by using it´s API by going to the API tab. There you´ll find our documentation and if you know something about programming, you´ll be able to make your own trading bots based on owr market forecasts.',
                href: 'eeiFnSiaTng'
            },
            obj2: {
                title: 'Api Main Functionalitites',
                msg: 'Our Api includes many functionalitites, including getting all cryptocurrency market forecasts and starting / stopping binance automatic trading and changing it´s trading variables. If you go to the API tab, there´s a description of each endpoint and each endpoint´s params and JSON return object. Also, we have a python module for you to work with our API more easily',
                href: 'fAYQKEHXpF8'
            },
            obj3: {
                title: 'Binance Trading',
                msg: 'In order for you to have more flexibility, we have a lot of trading variables that you can use, including public models used, private models used, stablecoin (your trading base coin), loss threshold (how much money you´re willing to lose before shutting down trading completly), model_exposure (max. money that a model can say to buy, in % of portfolio (0.2, not 20%)), model_exposure_priv (model_exposure, but for private models), percentage portfolio per trade (if the model finds a good trade, how much money should go there, in % of portfolio), Binance Apikey and Binance SecretKey (for us to be able to communicate with your binance account). If any error happens, it will be shown in the Binance Integration tab. You can see and change these tarding variables in the Binance Integration tab and using our API',
                href: 'ynoj8SQrMao'
            },
            obj4: {
                title: 'Explanation on Private Models',
                msg: 'Private Models are models for specific cryptocurrencies and / or specific times that you might be interested in. They are not as well trained as public models.',
                href: ''
            },
            obj5: {
                title: 'Google Accounts & Apikey password param',
                msg: 'If you have a normal account (you signed up using username, email and password), the password param in your api requests is your account´s password. If you have a google account (you signed up using google), the password param in your api requests is your secret key, which you can find by going to the account tab or the API tab (only available for google users)',
                href: ''
            }
        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>