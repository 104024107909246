<template>
<div class="backdrop" @click.self="close">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
    <div class="modal">
        <h1 class="titleokx">Binance Integration</h1>
        <div>
            <div class="englobeokxlabelandinput">
                <div class="okx">Binance Apikey: </div>
                <input type="password" ref="apikey" class="okxinp">
                    <button class="moveright" @click="copyTextToClipboardApikey">
                        <i class="fas fa-copy"></i>
                    </button>
                    <button class="moveright eye-icon" @click="showit('apikey')">
                        <i class="fa fa-eye" ref="apikeyi"></i>
                    </button>
                </input>
            </div>
            <div class="englobeokxlabelandinput">
                <div class="okx">Binance Secret Key: </div>
                <input type="password" ref="secretkey" class="okxinp">
                    <button class="moveright" @click="copyTextToClipboardSecretKey">
                        <i class="fas fa-copy"></i>
                    </button>
                    <button class="moveright eye-icon" @click="showit('secretkey')">
                        <i class="fa fa-eye" ref="secretkeyi"></i>
                    </button>
                </input>
            </div>
            <div style="color: green; margin-left: 10px; margin-top: 10px" ref="chnaged"></div>
        </div>
        <div>
            <button class="glow-on-hover" @click="handleChange" style="width: 90%;">Change Binance Data</button>
            <div style="margin-left: 10px; margin-top: 10px; color: green;" ref="chnaged"></div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'OKX',
    props: {
        password: {
            type: String
        },
        changing: {
            type: String
        }
    },
    data(){
        return {
            okxApikey: '',
            okxApikeyVmodel: '',
            okxSecretkey: '',
            wrong: false
        }
    },
    mounted(){
        fetch('https://indertct.me/api/okxGet', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: 'Dinis'})})
                .then(res => res.json())
                .then(data => {
                    if(data.code == 0){
                    this.okxApikey = data.Apikey;
                    this.okxSecretkey = data.SecretKey;
                    this.$refs.apikey.value = this.okxApikey;
                    this.$refs.secretkey.value = this.okxSecretkey;
                    } else if (data.code == 1){
                        console.log(data);
                    }
                })
                .catch(err => console.log(err))
    },
    methods: {
        close(){
            this.$emit('close')
        },
        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function() {
                console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
        copyTextToClipboardApikey(){
            this.copyTextToClipboard(this.$refs.apikey.value);
        },
        copyTextToClipboardSecretKey(){
            this.copyTextToClipboard(this.$refs.secretkey.value);
        },
        copyTextToClipboardPassphrase(){
            this.copyTextToClipboard(this.$refs.passphrase.value);
        },
        showit(refd){
            var passwordInput = this.$refs[refd]
            var eyeIcon = this.$refs[refd + 'i']
            if (passwordInput.type === 'password') {
                passwordInput.type = 'text';
                eyeIcon.classList.remove('fa-eye');
                eyeIcon.classList.add('fa-eye-slash');
            } else {
                    passwordInput.type = 'password';
                    eyeIcon.classList.remove('fa-eye-slash');
                    eyeIcon.classList.add('fa-eye');
            }
        },
        handle1(){
            this.okxMode = 1;
            this.modeDesc = '(Real trading)';
        },
        handle0(){
            this.okxMode = 0;
            this.modeDesc = '(Demo trading)';
        },
        handleChange(){
            fetch('https://indertct.me/api/okxPost', 
            {
                method: 'POST', 
                headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, 
                body: JSON.stringify(
                    {
                        Name: localStorage.getItem('Username'), 
                        OKX_A: this.$refs.apikey.value, 
                        OKX_S: this.$refs.secretkey.value, 
                    }
                )
            }
            )
                .then(res => res.json())
                .then(data => console.log(data.code, data.msg))
                .catch(err => console.log(err))
            this.$refs.chnaged.innerHTML = 'Changed';
        },
        computeA(){
            if(!(this.$refs.apikey || this.okxApikey)){
                return '';
            } else if (!this.$refs.apikey.value){
                return this.okxApikey;
            } else if (this.$refs.apikey.value){
                return this.$refs.apikey.value;
            }
        },
        computeS(){
            if(!(this.$refs.secretkey || this.okxSecretkey)){
                return '';
            } else if (!this.$refs.secretkey.value){
                return this.okxSecretkey;
            } else if (this.$refs.secretkey.value){
                return this.$refs.secretkey.value;
            }
        }
    },
    computed: {
        okxApikeyF(){
            return this.computeA();
        },
        okxSecretkeyF(){
            return this.computeS();
        },
        okxPassphraseF(){
            return this.computeP();
        },
        modeDesc(){
            if(this.okxMode == 0){
                return '(Real Trading)'
            } else if(this.okxMode == 1){
                return '(Demo trading)'
            }
        }
    }
}
</script>

<style scoped>
input.okxinp{
    width: 175px;
    height: 20px;
}

h1.titleokx{
    margin-top: 10px;
}

.modal{
        width: 270px;
        padding: 20px;
        margin: 100px auto;
        background: white;
        border-radius: 30px;
}

div.wro{
    display: inline-block;
     align-items: center;
}
div.okx{
    font-weight: bold;
    margin: 5px;
    margin-left: 0;
}
.moveright{
    width: 26px;
    height: 26px;
    background-color: #eee;
    margin-bottom: 10px;
}
.moveright:hover{
    background-color: #999;
    transition: background-color 0.3s;
    cursor: pointer;
}
</style>