<template>
    <Line :id="hello" :options="chartOptions" :data="chartDataF" />
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
    name: 'BarChart',
    components: { Line },
    data(){
        return {
            dates: '',
            realdata: '',
            chartInstance: null,
            data: ''
        }
    },
    props: {
        chartData: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        chartDataF(){
            return this.chartData;
        },
        chartOptions(){
            return this.options;
        },
        hello(){
            return this.chartData.datasets[0].label;
        }
    }
}

</script>