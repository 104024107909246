<template>
<div class="backdrop" @click.self="close">
    <div class="modal">
        <div>Paypal email</div>
        <input v-model="paypal_mail" placeholder="indertctaffiliate@gmail.com" style="width: 150px; border-radius: 10px; margin-top: 10px;"></input>
        <button class="glow-on-hover" @click="sendmail">Retrieve money</button>
        <span style="color:red;">{{ error_msg }}</span>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    data(){
        return {
            paypal_mail: '',
            error_msg: ''
        }
    },
    methods: {
        sendmail(){
            fetch('https://indertct.me/api/getMyMoney', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({Username: localStorage.getItem('AName'), Password: localStorage.getItem('APass'), email_paypal: this.paypal_mail})})
                .then(res => res.json())
                .then(data => {
                    if(data.code != 0){
                        this.error_msg = 'Something went wrong';
                    }
                })
        },
        close(){
            this.$emit('close');
        }
    },
    computed: {
        
    }
}
</script>

<style>

</style>