<template>
<div style="width: 100%;">
    <div class="social-media">
        <a href="https://x.com/IndertCT" target="_blank" class="social-link twitter" aria-label="X">
            <font-awesome-icon :icon="['fab', 'twitter']" />
        </a>
        <a href="https://www.instagram.com/indertct/" target="_blank" class="social-link instagram" aria-label="Instagram">
            <font-awesome-icon :icon="['fab', 'instagram']" />
        </a>
        <a href="https://www.youtube.com/@indertcts" target="_blank" class="social-link youtube" aria-label="YouTube">
            <font-awesome-icon :icon="['fab', 'youtube']" />
        </a>
        <a href="https://www.tiktok.com/@indertct" target="_blank" class="social-link tiktok" aria-label="TikTok">
            <font-awesome-icon :icon="['fab', 'tiktok']" />
        </a>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style scoped>
.social-media {
    display: flex;
    margin-left: 2%;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: -15px;
}

.social-link {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #333;
    color: white;
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    transition: background-color 0.3s;
}

.social-link:hover {
    background-color: #555;
}

.twitter {
    background-color: #000000;
}

.instagram {
    background-color: #e4405f;
}

.youtube {
    background-color: #ff0000;
}

.tiktok {
    background-color: #DC143C;
}

.social-link i {
    vertical-align: middle;
}
</style>