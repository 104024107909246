<template>
<div v-if="show">
        <div class="backdrop" @click.self="close">
            <div v-if="crList" class="modal">
                <div v-for="cr in crList">
                    <div class="enclose" v-if="!crlist.includes(cr)">
                        <div class="crypto">{{ cr }}</div>
                        <div class="add" @click.self="handleNewCr(cr)">+</div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>

export default {
    props: ['show', 'crlist'],
    data(){
        return {
            crList: []
        }
    },
    mounted(){
        fetch('https://indertct.me/api/getGlobal', {method: 'GET', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}})
            .then(res => res.json())
            .then(data => {
                if(data.code == "0"){
                    this.crList = data.cryptosGlobal;
                }
            })
    },
    methods: {
        close(){
            this.$emit('close')
        },
        handleNewCr(cr){
            this.$emit('new', cr);
        }
    }
}
</script>

<style>
div .request{
    align: center;
    text-align: center;
    background: #eee;
    border-radius: 10px;
    width: 130px;
    height: 50px;
    display: grid;
    margin: 10px;
    place-items: center;
    margin-top: 20px;
}

div .request:hover{
    background: #999;
    transition: background-color 0.25s; 
    cursor: pointer;
}

.backdrop{
        top: 0;
        position: fixed;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
}

.modal{
        width: 150px;
        padding: 20px;
        margin: 100px auto;
        background: white;
        border-radius: 30px;
}

.modal div .enclose{
    display: inline-block;
    background: white;
    border-radius: 10px;
    margin-top: 10px;
}
.modal .crypto{
    display: inline-block;
}

.modal .add{
    display: inline-block;
    background: #eee;
    border-radius: 3px;
    margin-left: 30px;
}
.modal .add:hover{
    background: #999;
    cursor: pointer;
}
</style>