<template>
<body v-show="mode === 0">
    <div class="background">
        <div class="shape"></div>
        <div class="shape"></div>
    </div>
    <section ref="form">
        <h3>Log in (Affiliates)</h3>
            <label for="Name">
                Username Or Email
            </label>
            <input type="text" placeholder="Username or Email" id="Name" v-model="name">

            <label for="Pass">Password</label>
            <input type="password" placeholder="Password" id="Pass" v-model="password">

            <button id="btn" type="button" class="glow-on-hover" @click="handleLogIn">Log in</button>
            <div class="forget-pass" @click="openreset">Forgot your password?</div>
            <div ref="feedk" class="feedback" id="non" style="width: 90%; margin: 10px; font-weight: bold;"></div>
            <div style="width: 100%; height: 3px; background-color: #000000; float: center; margin-top: 20px; margin-bottom: -10px;"></div>
            <div>
                <GoogleLogin :callback="handleGoogleLogin" auto-login>
                    <button style="display: flex; align-items: center; height: 4%; width: 100%; border-radius: 10px; margin-left: 5%;">
                        <div class="nsm7Bb-HzV7m-LgbsSe-Bz112c" style="width: 22px; height: 22px;"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="LgbsSe-Bz112c"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg></div>
                        <div style="padding: 20px;">
                            <span style="color: black; font-size: auto;">Log in with google</span>
                        </div>
                    </button>
                </GoogleLogin>
            </div>
            <div style="display: flex;">
                <div style="margin-top: 8px; text-align: center; width: 100%; font-size: 15px;">Or <router-link :to="{name: 'affsignup'}"> Sign Up!</router-link></div>
            </div>
            <div class="affiliates-link" style="margin-top: 5%; margin-left: -10%;">
                <router-link :to="{ name: 'login' }">Main hub</router-link>
            </div>
    </section>
</body>
<body v-show="mode === 1">
    <div class="header">
        <h1>Dashboard</h1>
        <div @click="logout" class="logout">Logout</div>
    </div>
    <div class="content">
        <div class="welcome">
            <h3>Welcome, {{ otherr_name }}!</h3>
            <h5>Your email: {{ email }}</h5>
            <h5>Your affiliate link:<a :href="'https://jusupaq.systeme.io/indertct?i='+other_name" target="_blank" style="margin-right: 5px; margin-left: 5px;">https://jusupaq.systeme.io/indertct?i={{other_name}}</a> or <a :href="'https://indertct.me/signup?i='+other_name" style="margin-right: 5px; margin-left: 5px;" target="_blank">https://indertct.me/signup?i={{other_name}}</a></h5>
            <span style="margin-top:20px;">Note: You can deliver both the signup page link <a target="_blank" :href="'https://indertct.me/signup?i='+other_name" style="margin-right: 3px; margin-left: 5px;">https://indertct.me/signup?i={{other_name}}</a> and the landing page link <a target="_blank" :href="'https://jusupaq.systeme.io/indertct?i='+other_name" style="margin-right: 5px; margin-left: 5px;">https://jusupaq.systeme.io/indertct?i={{other_name}}</a></span>
        </div>
        <div class="information">
            <div>
                <h3>You can find more information about indertCT by coming <a href="https://jusupaq.systeme.io/indertct" target="_blank">here</a></h3>
                <h2 style="margin-top: 20px; margin-bottom: 20px;"><a href="https://drive.google.com/drive/folders/1BLCWoKZyA1zRGegeRyFSNeg9bXRlPJK5?usp=drive_link" target="_blank">Ready-to-use content to kickstart your promotions!</a></h2>
            </div>
        </div>
        <div class="stats">
            <affcomp name="Signup page redirects" :n="redirects" msg="The number of times someone clicked on a buttom to go to the signup page from the landing page" note="" />
            <affcomp name="Signups" :n="signups" msg="The number of people who signed up (made an account) under your link" note="Does not mean that they paid" />
            <affcomp name="Checkouts" :n="checkouts" msg="The number of checkout visits your customers made under your link" note="" />
            <affcomp name="Money owed" :n="money_owed" msg="How much money we owe you. Please reach out to us at indertct@gmail.com to get it, or try the button below (still in experimental phase) to get it through paypal"><button class="glow-on-hover" @click="openpaypal">Get money through paypal</button></affcomp>
            <affcomp name="Sales" :n="sales" msg="The number of completed checkouts (sales)" note="" />
            <affcomp name="Views" :n="views" msg="Landing page views under your link" note="" />
            <affcomp name="Sales quoficinet" :n="quoficient+'%'" msg="The percentage of the money that goes to you from the total revenue you bring in" />
            <affcomp name="Checkout rate" :n="(Math.round((sales / checkouts)*10000)/100).toString()+'%'" msg="Percentgae of people who completed the checkout (sales / checkouts)" note="" />
            <affcomp name="Landing page to signup page rate" :n="(Math.round((redirects / views)*10000)/100).toString()+'%'" msg="Percentage of people who were on the landing page and from there went to the sign up page (signup page views / landing page views)" note="" />
            <affcomp name="Sign up page to signup rate" :n="(Math.round((signups / redirects) * 10000)/100).toString()+'%'" msg="Percentage of people who were on the signup page and actually signed up ( signups / signup page views)" note="" />
            <affcomp name="Overall conversion rate" :n="(Math.round((sales / views) * 10000)/100).toString()+'%'" msg="Percentage of people who started the funnel and actually bought the product (sales / landing page views)" note="" />
        </div>
    </div>
</body>
<paypal_aff v-if="showPaypal" @close="closePaypal" />
<affpassreset :show="showPass" @close="closePass" />
</template>

<script>
import affpassreset from '../components/passresetAff.vue';
import affcomp from '../components/affcomp.vue';
import paypal_aff from '../components/paypal_aff.vue';

export default {
    components: { affcomp, affpassreset, paypal_aff },
    name: '',
    data(){
        return {
            name: '',
            password: '',
            mode: 0,
            redirects: 0,
            signups: 0,
            checkouts: 0,
            money_owed: 0,
            sales: 0,
            views: 0,
            quoficient: 0,
            other_name: '',
            otherr_name: '',
            email: '',
            showPass: false,
            showPaypal: false
        }
    },
    mounted(){
        document.title = 'Affiliates Login'
        document.getElementById('mainBody').style['background-color'] = 'black';
        if (localStorage.getItem('AName') && localStorage.getItem('APass')){
            this.name = localStorage.getItem('AName');
            this.password = localStorage.getItem('APass');
            this.handleLogIn();
        }
    },
    methods: {
        closePaypal(){
            this.showPaypal = false;
        },
        openpaypal(){
            this.showPaypal = true;
        },
        closePass(){
            this.showPass = false;
        },
        openreset(){
            this.showPass = true;
        },
        logout(){
            localStorage.setItem('AName', '');
            localStorage.setItem('APass', '');
            this.name = '';
            this.password = '';
            this.mode = 0;
        },
        handleLogIn(){
            fetch('https://indertct.me/api/AffiliatePOST', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ Name: this.name, password: this.password})})
                .then(res => res.json())
                .then(data => {
                    if(data.code == 0){
                        this.otherr_name = this.name;
                        this.redirects = data.data.redirects;
                        this.checkouts = data.data.checkouts;
                        this.signups = data.data.signups;
                        this.money_owed = data.data.money_owed.toString()+"$";
                        this.sales = data.data.sales;
                        this.views = data.data.views;
                        this.quoficient = Math.round(data.data.quoficient * 100).toString();
                        this.email = data.data.email;
                        this.mode = 1;
                        document.title = 'Affiliates Dashboard';
                        this.other_name = data.data.id;
                        localStorage.setItem('AName', this.name);
                        localStorage.setItem('APass', this.password);
                    } else {
                        console.log(data.code, data.msg);
                    }
                })
                .catch(err => console.log(err))
        },
        handleGoogleLogin(response){
            fetch('https://indertct.me/api/googleAffLogin', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ code: response.code })})
                .then(res => res.json())
                .then(data => {
                    if(data.code == 0){
                        localStorage.setItem('AName', data.data.name);
                        localStorage.setItem('APass', data.data.password);
                        this.otherr_name = data.data.name;
                        this.other_name = data.data.id;
                        this.redirects = data.data.redirects;
                        this.checkouts = data.data.checkouts;
                        this.signups = data.data.signups;
                        this.money_owed = data.data.money_owed.toString()+"$";
                        this.sales = data.data.sales;
                        this.views = data.data.views;
                        this.email = data.data.email;
                        this.quoficient = Math.round(data.data.quoficient * 100).toString();
                        this.mode = 1;
                    } else {
                        console.log(data.code, data.msg);
                        this.$refs.feedk.style.color = 'red';
                        this.$refs.feedk.innerHTML = data.msg;
                    }
                })
                .catch(err => console.log(err))
        }
    },
    computed: {
        
    },
    watch: {
        mode(newval, oldVal){
            if (newval === 1){
                document.getElementById('mainBody').style['background-color'] = 'white';
                document.title = 'Affiliate Dashboard';
            } else if (newval == 0){
                document.getElementById('mainBody').style['background-color'] = 'black';
                document.title = 'Affiliate LogIn;'
            }
        }
    }
}
</script>

<style scoped media="screen">
.logout:hover{
    cursor: pointer;
    text-decoration: underline;
}
body {
            background-color: white;
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
        }

        .header {
            width: 100%;
            background-color: #505050;
            padding: 20px;
            color: #ffffff;
            text-align: center;
        }

        .header h1 {
            margin: 0;
            font-size: 28px;
        }

        .content {
            padding: 20px;
            padding-bottom: 40px;
            margin-top: 40px;
            background-color: white;
            margin: 20px auto;
            width: 90%;
            max-width: 1200px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }

        .welcome {
            margin-bottom: 20px;
        }

        .welcome h3 {
            margin: 0 0 10px;
            font-size: 24px;
            color: #333333;
        }

        .welcome h5 {
            margin: 0;
            font-size: 18px;
            color: #666666;
        }

        .stats {
            margin-top: 20px;
            font-size: 18px;
            color: #333333;
        }
div.forget-pass{
    width: 100%;
    text-align: center;
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: -10px;
}
div.forget-pass:hover{
    cursor: pointer;
}
*,
      *:before,
      *:after{
          padding: 0;
          margin: 0;
          box-sizing: border-box;
      }
      body{
          background-color: #080710;
      }
      .background{
          width: 430px;
          height: 520px;
          position: absolute;
          transform: translate(-50%,-50%);
          left: 50%;
          top: 50%;
          color: black;
      }
      .background .shape{
          height: 200px;
          width: 200px;
          position: absolute;
          border-radius: 50%;
      }
      .shape:first-child{
          background: linear-gradient(
              #1845ad,
              #23a2f6
          );
          left: -80px;
          top: -80px;
      }
      .shape:last-child{
          background: linear-gradient(
              to right,
              #ff512f,
              #f09819
          );
          right: -30px;
          bottom: -80px;
      }
      section{
          height: 600px;
          width: 400px;
          background-color: rgba(255,255,255,0.13);
          position: absolute;
          transform: translate(-50%,-50%);
          top: 50%;
          left: 50%;
          border-radius: 10px;
          backdrop-filter: blur(10px);
          border: 2px solid rgba(255,255,255,0.1);
          box-shadow: 0 0 40px rgba(8,7,16,0.6);
          padding: 50px 35px;
      }
      section *{
          font-family: 'Poppins',sans-serif;
          color: #ffffff;
          letter-spacing: 0.5px;
          outline: none;
          border: none;
      }
      section h3{
          font-size: 32px;
          font-weight: 500;
          line-height: 42px;
          text-align: center;
      }
      
      label{
          display: block;
          margin-top: 30px;
          font-size: 16px;
          font-weight: 500;
          float: left;
          padding: 10px;
      }
      input{
          display: block;
          height: 50px;
          width: 100%;
          background-color: rgba(255,255,255,0.07);
          border-radius: 3px;
          padding: 0 10px;
          margin-top: 8px;
          font-size: 14px;
          font-weight: 300;
      }
      ::placeholder{
          color: #e5e5e5;
      }
      button{
        display: block;
        margin: auto;
        margin-top: 30px;
        width: 100%;
        background-color: #ffffff;
        color: #080710;
        padding: 0 0;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer;
    }
    
      .glow-on-hover {
        width: 220px;
        height: 50px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
    }
    
    .glow-on-hover:before {
        content: '';
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;
    }
    
    .glow-on-hover:active {
        color: #000
    }
    
    .glow-on-hover:active:after {
        background: transparent;
    }
    
    .glow-on-hover:hover:before {
        opacity: 1;
    }
    
    .glow-on-hover:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        left: 0;
        top: 0;
        border-radius: 10px;
    }

    div.feedback{
        margin: auto;
        width: 50%;
        text-align: center;
        margin-top: 5px;
    }

    div.mini-feedback{
        color:#ff0000;
        font-size: 13px;
        margin-top: 4px;
        margin-bottom: -10px;
    }
    
    @keyframes glowing {
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }

    div.feedback{
        color: red;
        margin-top: 10px;
        font-size: 15px;
        margin: auto;
        text-align: center;
    }
.google-log-in {
    color: black;
}
.nsm7Bb-HzV7m-LgbsSe-BPrWId{
    color: black;
}
</style>