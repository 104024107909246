<template>
<api12 />
</template>

<script>
import api12 from '../components/apiBt.vue'
export default {
    components: { api12 },
    name: '',
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>