<template>
<div v-if="show">
    <div class="backdrop" @click.self="close">
        <div class="modal4">
            <h1>Make your own private model!</h1>
            <div>
                <label class="crlabel" for="crname">Cryptocurrency Name</label>
                <input v-model="cryptoName" class="crinput" id="crname"></input>
            </div>
            <div>
                <label class="crlabel" for="crhour">Model Time</label>
                <input v-model="hour" class="crinput" id="crhour"></input>
            </div>
            <div>
                <label class="crlabel" for="typecrp">Model Type</label>
                <input v-model="type" class="crinput" id="typecrp"></input>
            </div>
            <div>
                <label class="crlabel">Additional Info</label>
                <div>
                    <textarea v-model="additionalInfo" class="crtextarea"></textarea>
                </div>
            </div>
            <button class="glow-on-hover" @click="sendData">Submit</button>
            <div style="color: green; margin-top: 10px; margin-left: 5px;">{{ suc }}</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    data(){
        return {
            cryptoName: '',
            hour: '',
            additionalInfo: '',
            type: '',
            suc: ''
        }
    },
    props: ['show'],
    methods: {
        close(){
            this.$emit('close')
        },
        sendData(){
            fetch('https://indertct.me/api/crReqspriv', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({ AdditionalInfo: this.additionalInfo, Crypto: this.cryptoName, Hour: this.hour, Type: this.type})})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 2){
                        console.log(data.msg)
                    } else if (data.code == 0){
                        this.suc = 'Submited it'
                    }
                })
                .catch(err => console.log(err))
        }
    },
    computed: {
        
    }
}
</script>

<style>

</style>