<template>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
<div v-if="show">
    <div class="backdrop" @click.self="close">
        <div class="modal" style="width: 400px;" :class="{large: mode == 2}">
            <h1>Password for API for Google users</h1>
            <div v-if="mode === 0">
                <h3>Disclaimer</h3>
                <div>
                    <span>Please note that this password will never change and gives access to the most sensitive endpoints in your account. Make sure to keep it safe.</span>
                </div>
                <input type="checkbox" v-model="read" style="margin-top: 10px; border: 10px solid black;">I have read the disclaimer</input>
                <div v-if="read">
                    <button class="glow-on-hover" @click="sendMailCode" style="width: auto;">Recieve e-mail code</button>
                </div>
            </div>
            <div v-if="mode == 1">
                <vue-countdown :time="60000" v-slot="{ totalSeconds }">
                    <div>Time remaining: {{ totalSeconds }}</div>
                </vue-countdown>
                <div style="display:inline-block;">
                    <input v-model="code" class="code-input"></input>
                </div>
                <div>
                    <button class="glow-on-hover" @click="resetpass">See Apikey Password</button>
                </div>
                <div ref="feedback2" style="font-weight:bold; color: red;"></div>
            </div>
            <div v-if="mode == 2">
                <h2>Your Apikey Password Is:</h2>
                <div style="display: flex; align-items: center;">
                    <div style="margin-left: 0; border-radius: 0; border: 1px solid black; width: auto; padding: 5px; height: 20px;">{{ apikeypass }}</div>
                    <button class="fa fa-copy" style="height: 32px; width: 32px;" @click="copyApikey"></button>
                </div>
                <div style="font-weight: bold; margin-top: 10px; color: green;" ref="heyheyhey"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['show'],
    name: '',
    data(){
        return {
            read: false,
            mode: 0,
            code: '',
            apikeypass: ''
        }
    },
    methods: {
        close(){
            this.read = false;
            this.mode = 0;
            this.$emit('close');
        },
        copyApikey(){
            this.copyTextToClipboard(this.apikeypass);
            this.$refs.heyheyhey.innerHTML = 'Copied it!';
        },
        fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;
    
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
          return;
            }
        navigator.clipboard.writeText(text).then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
    },
    sendMailCode(){
        fetch('https://indertct.me/api/PasswordResetG', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({auth: localStorage.getItem('Username')})})
            .then(res => res.json())
            .then(data => {
                if (data.code == 0){
                    this.mode = 1;
                } else {
                    console.log(data.msg);
                }
                })
            .catch(err => console.log(err))
    },
    resetpass(){
        fetch('https://indertct.me/api/ReallyChangepassG', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({code: this.code, auth: localStorage.getItem('Username')})})
            .then(res => res.json())
            .then(data => {
                if (data.code == 0){
                    this.apikeypass = data.data;
                    this.mode = 2;
                } else {
                    this.$refs.feedback2.innerHTML = data.msg;
                }
            })
            .catch(err => console.log(err))
    }
    },
    computed: {
        
    }
}
</script>

<style>
.large{
    width: 800px;
}
.code-input{
    border: 2px solid black;
    border-radius: 10px;
}
.inputbin{
    width: 80%;
    border-radius: 10px;
    margin-top: 20px;
}
</style>