<template>
    <model />
</template>

<script>
import model from '../components/modelComp.vue'
export default {
    name: '',
    components: { model },
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>