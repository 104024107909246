<template>
<div class="backdrop" @click.self="close" v-if="showConsent">  
  <div class="cookie-consent-overlay">
    <div class="cookie-consent-box">
      <h2>Cookie Consent</h2>
      <p>
        We use cookies to store your username, email, and a limited time token for authentication.
        By clicking "Accept", you consent to our use of cookies.
      </p>
      <div class="cookie-consent-buttons">
        <button @click="acceptCookies" class="cookie-btn">Accept</button>
        <button @click="declineCookies" class="cookie-btn">Decline</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      showConsent: !localStorage.getItem('cookieConsent')
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'true');
      this.showConsent = false;
    },
    declineCookies() {
      this.showConsent = false;
      localStorage.setItem('cookieConsent', 'true');
    },
    close(){
      this.showConsent = false;
    }
  }
};
</script>

<style scoped>
.cookie-btn{
    border-radius: 10px;
    width: 200px;
    height: 50px;
    background-color: #fffafa;
}
.cookie-btn:hover{
  cursor: pointer;
  background-color: #fff5ee;
}
.cookie-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cookie-consent-box {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.cookie-consent-buttons button {
  margin: 10px;
}
</style>