<template>
<div class="backdrop" @click.self="close" v-show="show">  
  <div class="modal" style="width: 400px;">
    <h1>Password reset</h1>
    <div v-if="mode == 0">
        <div>
        <span>Please put your e-mail or username in the form below</span>
        </div>
        <div>
            <div><label>Email or Username</label></div>
            <div><input class="inputbin" v-model="auth"></input></div>
            <div><button class="glow-on-hover" @click="sendemail">Reset Password</button></div>
            <div ref="feedback" style="font-weight:bold; color: red;"></div>
        </div>
    </div>
    <div v-if="mode == 1">
        <vue-countdown :time="60000" v-slot="{ totalSeconds }">
            <div>Time remaining: {{ totalSeconds }}</div>
        </vue-countdown>
        <div style="display:inline-block;">
            <input v-model="code" class="code-input"></input>
        </div>
        <div>
            <button class="glow-on-hover" @click="resetpass">Reset Password</button>
        </div>
        <div ref="feedback2" style="font-weight:bold; color: red;"></div>
    </div>
    <div v-if="mode == 2">
        <div>
            <div>
                <label>New Password</label>
            </div>
            <div>
                <input class="inputbin" v-model="password1" style="margin-top: 5px; margin-bottom: 10px;" />
            </div>
        </div>
        <div>
            <div>
                <label>Confirm Password</label>
            </div>
            <div>
                <input class="inputbin" v-model="password2" style="margin-top: 5px; margin-bottom: 10px;" />
            </div>
            <button class="glow-on-hover" @click="chnagepass">Change Password</button>
            <div ref="feedback3" style="font-weight:bold; color: red;"></div>
        </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
    props: ['show'],
    name: '',
    data(){
        return {
            show2: false,
            showing: true,
            mode: 0,
            auth: '',
            validuser:false,
            code: '',
            password1: '',
            password2: ''
        }
    },
    methods: {
        sendemail(){
            fetch('https://indertct.me/api/validateEmailUsername', {method: 'POST',  headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ auth: this.auth })})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 0){
                        this.validuser = data.data;
                        if(this.validuser){
                            fetch('https://indertct.me/api/PasswordReset', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ auth: this.auth })})
                                .then(res => res.json())
                                .then(data => {
                                    if (data.code == 0){
                                        this.mode = 1;
                                    } else {
                                        console.log(data.msg);
                                    }
                                })
                                .catch(err => console.log(err))
                        }
                        else{
                            this.$refs.feedback.innerHTML = 'Invalid email or username';
                        }
                    } else {
                        console.log(data.msg);
                    }
                })
                .catch(err => console.log(err))

            
        },
        close(){
            this.$emit('close');
            this.mode = 0;
        },
        resetpass(){
            fetch('https://indertct.me/api/ReallyChangepass', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ auth: this.auth, code: this.code })})
                .then(res => res.json())
                .then(data => {
                    if(data.code == 0){
                        this.mode = 2;
                    } else {
                        console.log(data.msg);
                        this.$refs.feedback2.innerHTML = data.msg;
                    }
                })
                .catch(err => console.log(err))
        },
        chnagepass(){
            if (this.password1 === this.password2){
                fetch('https://indertct.me/api/changePass', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ auth: this.auth, pass: this.password1 })})
                    .then(res => res.json())
                    .then(data => {
                        if(data.code == 0){
                            this.close();
                        } else {
                            this.$refs.feedback3.innerHTML = data.msg;
                        }
                    })
            } else {
                this.$refs.feedback3.innerHTML = 'Password doesn´t match';
            }
        }
    },
    computed: {
        
    }
}
</script>

<style scoped>
.code-input{
    border: 2px solid black;
    border-radius: 10px;
}
.inputbin{
    width: 80%;
    border-radius: 10px;
    margin-top: 20px;
}
</style>