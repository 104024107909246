import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import vue3GoogleLogin from 'vue3-google-login'
import VueCountdown from '@chenfengyuan/vue-countdown';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTwitter, faInstagram, faYoutube, faTiktok)

const CLIENT_ID = '525480451702-fhhg0pimrkf15pdh0q6p1bhh2mpi35rp.apps.googleusercontent.com';

createApp(App).use(router).use(VueTelInput).use(VuePlyr, { plyr: {} }).use(vue3GoogleLogin,{
    clientId:CLIENT_ID,
    scope: 'profile email openid',
    responseType: 'token'
}).component(VueCountdown.name, VueCountdown).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
