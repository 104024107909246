<template>
    <settings />
</template>

<script>
import settings from '../components/settings_comp.vue'
export default {
    name: '',
    components: { settings },
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>

</style>