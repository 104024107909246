<template>

    <div style="margin-bottom: -15px; margin-top: -15px;">

            <input type="file" accept="image/*" class="hidden" ref="file" @change="change" />
            
            <div class="image-container">
                <img :src="src" alt="Profile Picture" 
                    style="height: 240px; width: 240px; border-radius: 50%; margin-bottom: 0px;" 
                v-show="!upload">
                    
                </img>
                <canvas alt="Profile Picture" 
                    style="height: 240px; width: 240px; border-radius: 50%; margin-bottom: 0px;" 
                    v-show="upload"
                    id="canvas_uploaded_image"
                >
                    
                </canvas>

                <div class="overlay" ref="overlay" @mouseenter="showIt" @mouseleave="hideIt">
                    <div style="width: 40px; height: 40px; border-radius: 50%; display: none; color: white;" v-show="show">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="white" height="40px" width="40px" version="1.1" id="Capa_1" viewBox="0 0 487 487" xml:space="preserve" class="camera-icon" @click="browse">
                        <g>
                            <g>
                                <path d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z     M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9    v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z     M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z     M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"/>
                            </g>
                        </g>
                        </svg>
                    </div>
                </div>
                
                <!-- Camera Icon Overlay -->
            </div>
            
    </div>

</template>

<script>
export default {
    name: '',
    props: ['src', 'upload'],
    data(){
        return {
            newsrc: '',
            show: false,
            userImg: '',
            src2: 'https://indertct.me/api/image_profile_get/'+localStorage.getItem('Username')
        }
    },
    async mounted(){
        try {
            const response = await fetch('https://indertct.me/api/imgUser', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            this.userImg = data.img;
            } catch (error) {
                console.error('Error:', error);
            }
    },
    methods: {
        browse() {
            this.$refs.file.click();
        },
        change(e) {

            let reader = new FileReader();
            const canvas = document.getElementById('canvas_uploaded_image');
            const ctx = canvas.getContext('2d');
            let croppedImageBlob;

            const img = new Image();

            img.onload = function(e) {
            const canvasSize = 240;
            canvas.width = canvasSize;
            canvas.height = canvasSize;

            const minSize = Math.min(img.width, img.height);
            const sx = (img.width - minSize) / 2;
            const sy = (img.height - minSize) / 2;

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, sx, sy, minSize, minSize, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(function(blob) {
                croppedImageBlob = blob;
                fetch('https://indertct.me/api/upload_image', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({file: document.getElementById('canvas_uploaded_image').toDataURL('image/jpeg')})})
                    .then(res => res.json())
                    .then(data => {
                        if (data.code == 2){
                            console.log(data.msg);
                        } else if (data.code == 0){
                            console.log("did it successfully");
                            this.$emit('isNowSelf');
                        }
                    })
                    .catch(err => console.log(err))
            }, 'image/jpeg'); // You can change the format if needed
            }
            this.$emit('true');

            reader.readAsDataURL(e.target.files[0]);
            
            reader.onload = (e) =>  {
                img.src = e.target.result;
            }
        },
        showIt(){
            this.show = true;
        },
        hideIt(){
            this.show = false;
        }
    },
    computed: {
        
    },
    watch: {
        src(before, after) {
            this.$emit('false');
        }
    }
}
</script>

<style>
.hidden {
    display: none;   
}
.image-container {
    position: relative;
    display: inline-block;
}

.camera-icon {
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.camera-icon:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
.overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center; 
    justify-content: center; 
    border-radius: 50%;
}
</style>