<template>
<div v-if="show">
    <div v-show="showing">
        <div class="backdrop" @click.self="close">
            <div class="modal4">
                <h1>Check some of our already made private models!</h1>
                <table class="gridModels">
                    <tr class="rowModels2">
                        <th class="index">Crypto</th>
                        <th class="index">Time</th>
                        <th class="index">Type</th>
                        <th class="index">Add</th>
                    </tr>
                    <tr v-for="dat in allPrivModels" class="rowModels">
                        <td class="data">{{ dat.crypto }}</td>
                        <td class="data">{{ dat.hour }}</td>
                        <td class="data">{{ dat.type }}</td>
                        <td class="data2">
                            <i class="fa-solid fa-plus" style="font-size: 30px; cursor: pointer;" @click="handlenewprivmodel(dat)"></i>
                        </td>
                    </tr>
                </table>
                <div style="width: 100%; height: auto; display:grid; align-items: center; margin-top: 15px;">
                <button class="glow-on-hover" style="height: 30px; width: 80%; margin-left: 10%; margin-bottom: 10px;" @click="openpriv">Make your own private model</button>
                </div>
            </div>
        </div>
    </div>
</div>
<privmodelRequest :show="showOwn" @close="closepriv"/>
</template>

<script>
import privmodelRequest from '../components/privmodelRequest.vue'

export default {
    name: '',
    components: { privmodelRequest },
    data(){
        return {
            allPrivModels: [],
            ids: [],
            showing: true,
            showOwn: false
        }
    },
    props: ['show', 'watching'],
    watch: {
        watching(newValue, oldValue){
            console.log(newValue);
            if (newValue != oldValue){
                this.fetchData();
            }
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },
        closepriv(){
            this.showOwn = false;
            this.showing = true;
        },
        openpriv(){
            this.showing = false;
            this.showOwn = true;
        },
        async handlenewprivmodel(dat){
           this.allPrivModels = this.allPrivModels.filter(element => element.id != dat.id);

            // Initialize csv as an empty string
            var csv = '';

            // Check if this.ids is initialized and is an array
            if (this.ids && Array.isArray(this.ids)) {
                // If this.ids is not empty, join the elements with a comma
                if (this.ids.length > 0) {
                    csv = this.ids.join(',') + ',';
                }

                // Append dat.id to csv
                csv += dat.id;

                // Push dat.id to this.ids
                this.ids.push(dat.id);
                } else {
                    // If this.ids is not initialized, initialize it as an array and add dat.id
                    this.ids = [dat.id];
                    csv = dat.id;
                }
            await fetch('https://indertct.me/api/changePrivs', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username'), modelprivcsv: csv})})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 2){
                        console.log(data.msg);
                    }
                })
                .catch(err => console.log(err))
            this.$emit('newCR');
        },
        fetchData(){
            fetch('https://indertct.me/api/getPrivModels', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username')})})
            .then(res => res.json())
            .then(data => {
                if (data.code == 2){
                    console.log(data.msg);
                }
                this.allPrivModels = data.data;
            })
            .catch(err => console.log(err))
            fetch('https://indertct.me/api/getModelsPrivUser', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username')})})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 2){
                        console.log(data.msg);
                    }
                    this.ids = data.data;
                    console.log(this.allPrivModels);
                })
                .catch(err => console.log(err))
        }
    },
    computed: {
        
    },
    mounted(){
        this.fetchData();
    }
}
</script>

<style>

</style>