<template>
<body class="IsCenter">
    <div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
        <h1 style="font-size: 50px; margin-left: -10px;">Binance Integration</h1>
        <div style="margin-top: 10px; margin-left: 10px;">Note: This feature is still in alpha, so it might not work too well. If you have any ideas on what could make it better or any problem you might have faced while using it, feel free to email us at indertct@gmail.com</div>
        <div style="margin-left: 10px;">
            <div>
                <h1 style="margin-left: -20px;">
                    Binance Apikey Data
                </h1>
                <div>
                    <button class="glow-on-hover" @click="openaskpass" style="width: 340px; height: 40px; margin-left: 0px; font-size: 17px; font-weight: bold; padding: 10px;">Change or see Binance Apikey data</button>
                </div>
            </div>
            <div style="margin-top: 50px;">
                <h1 style="margin-left: -20px;">
                    Binance Automatic AI Trading
                </h1>
                <div>
                    <h2>
                        Trading Variables
                    </h2>
                    <div>
                        <div class="dividerbn">
                            <div style="margin-bottom: 10px;">
                                <label for="stbl" class="binlabel">Basecoin</label>
                            </div>
                            <div>
                                <select ref="stablecoin" id="stbl" class="inputbin" v-model="stbl">
                                        <option v-for="quot in quoteBetter" :value="quot">{{ quot }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="dividerbn">
                            <div style="margin-bottom: 10px;">
                                <label for="stblll" class="binlabel">Loss Threshold *</label>
                            </div>
                            <div>
                                <input ref="lossTh" id="stblll" class="inputbin" type="number" min="0" v-model.number="lossTh"></input>
                            </div>
                        </div>
                        <div class="dividerbn">
                            <div style="margin-bottom: 10px;">
                                <label class="binlabel" for="bnnpopt">Percentage of portfolio per trade</label>
                            </div>
                            <div>
                                <input id="bnnpopt" class="inputbin" ref="percentage" min="0" max="1" type="number" v-model.number="percentage" placeholder="0.05 -> 5%"></input>
                            </div>
                        </div>
                        <div>
                            <button class="glow-on-hover" @click="changeData">Change Data</button>
                            <div style="margin-top: 10px; color: green" ref="feetka"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2>Manual Trading</h2>
                    <div>
                        <div>
                            <div>
                                <label>Quantity of base coin</label>
                            </div>
                            <div>
                                <input type="number" step="0.01" min="0" placeholder="0.0" class="inputbin" style="margin-bottom: 10px;" v-model.number="quantityBuy"></input>
                            </div>
                            Select a symbol:
                            <div>
                                <select v-model="symb" class="inputbin">
                                    <option v-for="symbol in symbols" :value="symbol">{{ symbol }}</option>
                                </select>
                            </div>
                            <div style="font-weight: bold; margin-left: 130px; margin-top: 20px; margin-bottom: 20px; font-size: 25px;">Or</div>
                            <div>
                                <div>Select quote:</div>
                                <div>
                                    <select v-model="quote_coin" class="inputbin">
                                        <option v-for="quot in quoteBetter" :value="quot">{{ quot }}</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div>Select base:</div>
                                <div>
                                    <select v-model="base_coin" ref="base_chose" class="inputbin">
                                        <option v-for="base in baseBetter" :value="base">{{ base }}</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div>Side:</div>
                                <div>
                                    <select v-model="trade_side" class="inputbin">
                                        <option :value="1">Buy</option>
                                        <option :value="0">Sell</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <button class="glow-on-hover" @click="handleTrade" style="font-weight: bold; width: 200px; margin-top: 20px; margin-left: 0px;">Trade</button>
                            </div>
                            <div ref="factfeet" style="margin: 10px; color: red;"></div>
                        </div>
                    </div>
                    <h2>Automatic Trading</h2>
                    <div>
                        <div style="color: green; font-weight: bold;" v-if="initialTr">Already Trading</div>
                        <div v-if="trading">
                            <div>
                                <button class="glow-on-hover" style="margin-left: -10px; margin-top: 20px;" @click="handleStopTrading">Stop Trading</button>
                            </div>
                        </div>
                        <div v-else>
                            <h3>Start Automatic Trading</h3>
                            <div>
                                <button class="glow-on-hover" style="width: 200px; font-weight: bold;" @click="handleStartOfTrading">Start Automatic Trading</button>
                            </div>
                        </div>
                        <div style="color: green; margin-top: 20px;" ref="automaticTradefeedback"></div>
                        <div v-if="trading" style="color: green; margin-top: 20px; font-weight: bold; margin-bottom: 10px;">
                            Trading
                        </div>
                        <div v-else style="color: red; margin-top: 20px; font-weight: bold; margin-bottom: 10px;">
                            Not trading
                        </div>
                    </div>
                    <div v-if="errors">
                        <div v-for="(error, index) in errors" style="margin-top: 10px;">
                            <error :error="error" @delete="deleteBasedOnIndex(index)" />
                        </div>
                    </div>
                </div>
                <div style="margin-top: 50px;">
                    <h2>
                        Public Models Used
                    </h2>
                    <div>
                        <table class="tablebin">
                            <tr class="rowheader">
                                <th class="indexbin">Crypto</th>
                                <th class="indexbin">Time</th>
                                <th class="indexbin">Name</th>
                                <th class="indexbin">Accuracy</th>
                                <th class="indexbin">In Use</th>
                                <th class="indexbin">Add / Take</th>
                                <th class="indexbin">Model Max Exposure</th>
                            </tr>
                            <tr v-for="modelB in modelsPub" class="rowdatabin">
                                <td class="datacbin" style="width: 30px;">{{ modelB.crypto }}</td>
                                <td class="datacbin" style="width: 30px;">{{ modelB.hour }}</td>
                                <td class="datacbin" style="width: 50px;">{{ modelB.name }}</td>
                                <td class="datacbin" style="width: 100px;">{{ modelB.accur }}</td>
                                <td class="datacbin" style="width: 80px;">
                                    <div class="status-wrapper">
                                        <div :class="{green: modelB.used, red: !modelB.used}" class="status-dot"></div>
                                        <div>{{ modelB.label }}</div>
                                    </div>
                                </td>
                                <td class="datacbin" style="width: 20px;">
                                    <div class="signal-wrapper">
                                        <i :class="{'fa-plus' : !modelB.used, 'fa-solid': !modelB.used, 'fa-circle-xmark': modelB.used, 'fa-regular': modelB.used}" style="font-size: 30px;" @click="handleTakeAdd(modelB)"></i>
                                    </div>
                                </td>
                                <td class="datacbin" style="width: 20px;">
                                    <div class="status-wrapper">
                                        <input type="number" class="expbininp" v-model.number="modelB.exposure" min="0" max="100"></input>%
                                        <div>
                                            <div class="arrow up" @click="increment(modelB)">▲</div>
                                            <div class="arrow down" @click="decrement(modelB)">▼</div>
                                        </div>
                                        <button class="glow-on-hover" style="cursor: pointer; font-size: 13px; width: 33%; heigth: 5px;" @click="changeData">Change Data</button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="modelNB in modelsNotPub" class="rowdatabin">
                                <td class="datacbin" style="width: 30px;">{{ modelNB.crypto }}</td>
                                <td class="datacbin" style="width: 30px;">{{ modelNB.hour }}</td>
                                <td class="datacbin" style="width: 50px;">{{ modelNB.name }}</td>
                                <td class="datacbin" style="width: 100px;">{{ modelNB.accur }}</td>
                                <td class="datacbin" style="width: 80px;">
                                    <div class="status-wrapper">
                                        <div :class="{green: modelNB.used, red: !modelNB.used}" class="status-dot"></div>
                                        <div>{{ modelNB.label }}</div>
                                    </div>
                                </td>
                                <td style="width: 20px;">
                                    <div class="signal-wrapper">
                                        <i :class="{'fa-plus' : !modelNB.used, 'fa-solid': !modelNB.used, 'fa-circle-xmark': modelNB.used, 'fa-regular': modelNB.used}" style="font-size: 30px; cursor: pointer;" @click="handleTakeAdd(modelNB)"></i>
                                    </div>
                                </td>
                                <td class="datacbin" style="width: 20px;">
                                    Not in use
                                </td>
                            </tr>
                        </table>
                    </div>
                    <h2>Private models</h2>
                    <div>
                        <table class="tablebin">
                            <tr class="rowheader">
                                <th class="indexbin">Crypto</th>
                                <th class="indexbin">Time</th>
                                <th class="indexbin">Name</th>
                                <th class="indexbin">Accuracy</th>
                                <th class="indexbin">In Use</th>
                                <th class="indexbin">Add / Take</th>
                                <th class="indexbin">Model Max Exposure</th>
                            </tr>
                            <tr v-for="modelB in modelsPriv" class="rowdatabin">
                                <td class="datacbin" style="width: 30px;">{{ modelB.crypto }}</td>
                                <td class="datacbin" style="width: 30px;">{{ modelB.hour }}h</td>
                                <td class="datacbin" style="width: 50px;">{{ modelB.name }}</td>
                                <td class="datacbin" style="width: 100px;">{{ modelB.accur }}</td>
                                <td class="datacbin" style="width: 80px;">
                                    <div class="status-wrapper">
                                        <div :class="{green: modelB.used, red: !modelB.used}" class="status-dot"></div>
                                        <div>{{ modelB.label }}</div>
                                    </div>
                                </td>
                                <td class="datacbin" style="width: 20px;">
                                    <div class="signal-wrapper">
                                        <i :class="{'fa-plus' : !modelB.used, 'fa-solid': !modelB.used, 'fa-circle-xmark': modelB.used, 'fa-regular': modelB.used}" style="font-size: 30px;" @click="handleTakeAddPriv(modelB)"></i>
                                    </div>
                                </td>
                                <td class="datacbin" style="width: 20px;">
                                    <div class="status-wrapper">
                                        <input type="number" class="expbininp" v-model.number="modelB.exposure" min="0" max="100">%</input>
                                        <div>
                                            <div class="arrow up" @click="increment(modelB)">▲</div>
                                            <div class="arrow down" @click="decrement(modelB)">▼</div>
                                        </div>
                                        <button class="glow-on-hover" style="cursor: pointer; font-size: 13px; width: 33%; heigth: 5px;" @click="changeData">Change Data</button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="modelNB in modelsNotPriv" class="rowdatabin">
                                <td class="datacbin" style="width: 30px;">{{ modelNB.crypto }}</td>
                                <td class="datacbin" style="width: 30px;">{{ modelNB.hour }}h</td>
                                <td class="datacbin" style="width: 50px;">{{ modelNB.name }}</td>
                                <td class="datacbin" style="width: 100px;">{{ modelNB.accur }}</td>
                                <td class="datacbin" style="width: 80px;">
                                    <div class="status-wrapper">
                                        <div :class="{green: modelNB.used, red: !modelNB.used}" class="status-dot"></div>
                                        <div>{{ modelNB.label }}</div>
                                    </div>
                                </td>
                                <td style="width: 20px;">
                                    <div class="signal-wrapper">
                                        <i :class="{'fa-plus' : !modelNB.used, 'fa-solid': !modelNB.used, 'fa-circle-xmark': modelNB.used, 'fa-regular': modelNB.used}" style="font-size: 30px; cursor: pointer;" @click="handleTakeAddPriv(modelNB)"></i>
                                    </div>
                                </td>
                                <td class="datacbin" style="width: 20px;">
                                    Not in use
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div style="margin-top: 20px;">* - maximum loss in a day to shut trading down (in units of the base coin)</div>
    </div>
    <div style="margin-top: 30px;">
        <div>Note: If your base coin does not have a direct exchange with a coin from your models in use, the trade will not be done and an error will show up in your log in this page</div>
        <div style="margin-top: 5px;">Note 2: The model exposure only works in each cycle of trading, so if you stop trading and start again it will reset the count for the money the models has. If you don´t want this, you can fight it by lowering your model_exposure and model_exposure_priv variables, here or on the API </div>
    </div>
</body>
<askPass :show="showaskPass" @close="closeaskpass" />
<startTrade :show="showst" @close="notshowst" @success="handleSuccess"/>
</template>

<script>
import error from './error.vue'
import askPass from '../components/askPassphrase.vue'
import startTrade from '../components/startTrade.vue'

export default {
    components: { askPass, startTrade, error },
    name: '',
    data(){
        return {
            okxTradingPairs: [],
            quantityBuy: 0.0,
            showaskPass: false,
            stbl: '',
            exposurePriv: [],
            exposurePub: [],
            lossTh: '',
            percentage: '',
            modelsPub: [],
            modelsPriv: [],
            modelsNotPub: [],
            modelsNotPriv: [],
            base: [],
            initialTr: '', 
            quote: [],
            symbols: [],
            symb: '',
            quote_coin: '',
            base_coin: '',
            trade_side: '',
            trading: '',
            errors: '',
            showst: false
        }
    },
    mounted(){
        document.title = 'Binance Integration';
        fetch('https://indertct.me/api/getMiscel', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body:JSON.stringify({Name: localStorage.getItem('Username')})})
            .then(res => res.json())
            .then(data => {
                const dataR = data.data;
                this.percentage = dataR.percentagePerTrade;
                this.stbl = dataR.Stablecoin;
                this.exposurePriv = dataR.model_exposure_priv;
                this.exposurePub = dataR.model_exposure_pub;
                this.lossTh = dataR.lossTH;
                this.modelsPub = dataR.modelsPub;
                this.modelsNotPub = dataR.modelsNotPub;
                this.modelsPriv = dataR.modelsPriv;
                this.modelsNotPriv = dataR.modelsNotPriv;
                this.$refs.lossTh.value = dataR.lossTH;
                this.$refs.stablecoin.value = dataR.Stablecoin;
                this.$refs.percentage.value = dataR.percentagePerTrade;
            })
            .catch(err => console.log(err))
        fetch('https://indertct.me/api/getTickers', {method: 'GET', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}})
            .then(res => res.json())
            .then(data => {
                this.base = data.data.base;
                this.quote = data.data.quote;
                this.symbols = data.data.symbol;
            })
            .catch(err => console.log(err))
        fetch('https://indertct.me/api/getTRHSt', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body:JSON.stringify({Name: localStorage.getItem('Username')})})
            .then(res => res.json())
            .then(data => {
                if(data.code == 0){
                    this.errors = data.data.errors;
                    this.trading = data.data.trading;
                    this.initialTr = data.data.trading;
                } else {
                    console.log(data.msg);
                }
            })
    },
    methods: {
        closeaskpass(){
            this.showaskPass = false;
        },
        notshowst(){
            this.showst = false;
        },
        startTrade3(){
            this.showst = true;
        },
        openaskpass(){
            this.showaskPass = true;
        },
        handleStopTrading(){
            fetch('https://indertct.me/api/handleTrading', {method: 'GET', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 2){
                        console.log(data.msg);
                    } else if (data.code == 0){
                        this.$refs.automaticTradefeedback.innerHTML = '<strong>Stoped Trading</strong>';
                        this.$refs.automaticTradefeedback.style.color = 'red';
                        this.trading = false;
                        this.initialTr = false;
                    }
                })
                .catch(err => console.log(err))
        },
        changeData(){
            fetch('https://indertct.me/api/postMiscel', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body:JSON.stringify(
                {
                    csvPriv: this.idsPriv,
                    csvPub: this.idsPub,
                    LossTh: this.lossTh,
                    Stablecoin: this.stbl,
                    csvPrivExp: this.expPriv,
                    csvPubExp: this.expPub,
                    percentage: this.percentage
                })} 
                )
                .then(res => res.json())
                .then(data => {
                    if (data.code == 2){
                        console.log(data.msg);
                    } else if (data.code == 0){
                        this.$refs.feetka.innerHTML = 'Changed Data';
                    }
                    })
                .catch(err => console.log(err))
        },
        increment(model){
            model.exposure += 1;
        },
        postData(){
            fetch('https://indertct.me/api/ChangeErrors', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body:JSON.stringify({ data: this.errors })})
                .then(res => res.json())
                .then(data => {
                    if (data.code != '0'){
                        console.log(data.msg);
                    }
                })
        },
        deleteBasedOnIndex(index){
            this.errors.splice(index, 1);
            this.postData();
        },
        decrement(model){
            model.exposure -= 1;
        },
        handleSuccess(){
            this.$refs.automaticTradefeedback.innerHTML = '<strong>Started Trading</strong>';
            this.$refs.automaticTradefeedback.style.color = 'green';
            this.trading = true;
            this.initialTr = false;
        },
        tocsv(value){
            var csv = '';
            value.forEach(element => {
                csv = csv + element + ','
            });
            return csv.slice(0, -1);
        },
        handleTrade(){
            if(((!this.quote_coin && !this.base_coin) || !this.symb) && !this.trade_side){
                this.$refs.factfeet.innerHTML = '<strong>Invalid input</strong>';
                this.$refs.factfeet.style.color = 'red';
            }
            fetch('https://indertct.me/api/manualTradebclk', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({ quantity: this.quantityBuy, symbol: this.symbolrl, buy: !!this.buy })})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 0){
                        this.$refs.factfeet.style.color = 'green';
                        this.$refs.factfeet.innerHTML = '<strong>Placed the order</strong>';
                    } else if (data.code == 2 || data.code == 1){
                        this.$refs.factfeet.innerHTML = '<strong>Something went wrong:<div>' + data.msg + '</div></strong>';
                        this.$refs.factfeet.style.color = 'red';
                    }
                })
        },
        handleTakeAdd(modelData){
            if (modelData.used){
                const elementIndex = this.modelsPub.findIndex(element => element.id === modelData.id);
                if (elementIndex !== -1) {
                    this.modelsPub.splice(elementIndex, 1);
                    this.exposurePub.splice(elementIndex, 1);
                }
                modelData.label = 'False';
                this.modelsNotPub.push(modelData);
            } else if (!modelData.used) {
                modelData.exposure = 50;
                this.modelsPub.push(modelData);
                this.exposurePub.push(50);
                this.modelsNotPub = this.modelsNotPub.filter(element => element.id != modelData.id)
                modelData.label = 'True';
            }
            modelData.used = !modelData.used;
            this.changeData();
        },
        handleStartOfTrading(){   
            this.startTrade3();
        },
        handleTakeAddPriv(modelData){
            if (modelData.used){
                const elementIndex = this.modelsPriv.findIndex(element => element.id === modelData.id);
                if (elementIndex !== -1) {
                    this.modelsPriv.splice(elementIndex, 1);
                    this.exposurePriv.splice(elementIndex, 1);
                }
                modelData.label = 'False';
                this.modelsNotPriv.push(modelData);
            } else if (!modelData.used){
                modelData.exposure = 50;
                this.modelsPriv.push(modelData);
                this.exposurePriv.push(50);
                this.modelsNotPriv = this.modelsNotPriv.filter(element => element.id != modelData.id)
                modelData.label = 'True';
            }
            modelData.used = !modelData.used;
            this.changeData();
        }
    },
    computed: {
        idsPriv(){
            var csv = '';
            this.modelsPriv.forEach(element => {
                csv = csv + element.id + ',';
            })
            return csv.slice(0, -1);
        },
        idsPub(){
            var csv = '';
            this.modelsPub.forEach(element => {
                csv = csv + element.id + ',';
            })
            return csv.slice(0, -1);
        },
        expPriv(){
            var csv = '';
            this.modelsPriv.forEach(element => {
                csv = csv + element.exposure / 100 + ',';
            })
            return csv.slice(0, -1);
        },
        expPub(){
            var csv = '';
            this.modelsPub.forEach(element => {
                csv = csv + element.exposure / 100 + ',';
            })
            return csv.slice(0, -1);
        },
        quoteBetter(){
            var quoteBetter = [];
            if (!this.base_coin){
                for (let index = 0; index < this.quote.length; index++) {
                    const element = this.quote[index];
                    if (!quoteBetter.includes(element)){
                        quoteBetter.push(element);
                    }
                }
                return quoteBetter;
            } else {
                this.base.forEach((element, index) => {
                    if (element == this.base_coin){
                        quoteBetter.push(this.quote[index]);
                }
            })
            }
            return quoteBetter
        },
        baseBetter(){
            var quotes = [];
            if (!this.quote_coin){
                var makeCoins = this.stbl;
            } else {
                var makeCoins = this.quote_coin;
            }
            this.quote.forEach((element, index) => {
                if (element == makeCoins){
                    quotes.push(this.base[index]);
                }
            })
            return quotes;
        },
        symbolrl(){
            if(!this.symb){
                return this.base_coin + this.quote_coin;
            } else if (this.symb){
                return this.symb;
            }
        }
    }
}
</script>

<style scoped>
.inputbin{
    border-radius: 20px;
    border: 2px solid black;
    width: 300px;
}
label.binlabel{
    font-weight: bold;
    margin-left: 10px;
}
div.dividerbn{
    margin-bottom: 30px;
}
i.fa-plus:hover{
    cursor: pointer;
}
i.fa-circle-xmark:hover{
    cursor: pointer;
}
.arrow:hover{
    cursor: pointer;
}
select.inputbin{
    margin-bottom: 10px;
}
.tablebin {
            width: 100%;
            border-collapse: collapse;
        }
        .tablebin, .tablebin th, .tablebin td {
            border: 1px solid #ddd;
            padding: 8px;
        }
        .rowheader {
            background-color: #f2f2f2;
            text-align: left;
        }
        .rowdatabin:hover {
            background-color: #f1f1f1;
        }
        /* 
        .rowdatabin:nth-child(even) {
            background-color: #f3f3f3;
        }  
        */
        .indexbin {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: center;
            background-color: #000000;
            color: white;
        }
        .datacbin {
            text-align: center;
        }
        .signal-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .signal-icon {
            font-size: 20px;
            cursor: pointer;
        }
        .status-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 5px;
        }
        .green {
            background-color: green;
        }
        .red {
            background-color: red;
        }
        .status-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
.expbininp{
    border: 0;
    margin-right: 0px;
    width: 6%;
    padding-right: -20px;
}
.glow-on-hover{
    margin-bottom: 10px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.expbininp[type="number"]:hover{
    background-color: #f1f1f1;
}
</style>