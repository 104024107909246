<template>
<account @newImg="emit"/>
</template>

<script>
import account from '../components/accountComp.vue'

export default {
    components: { account },
    name: 'Account Window',
    methods:{
        emit(data){
            this.$emit('newImg', data);
        }
    }
}
</script>

<style>

</style>