<template>
<dashboard />
</template>

<script>
import dashboard from '../components/dashboard.vue'
export default {
    name: 'dashboard view',
    components: { dashboard }
}

</script>

<style>

</style>