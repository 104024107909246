<template>
<div class="backdrop" v-show="show" @click.self="close">
    <div class="modal" style="width: 50%;">
        <h1>An e-mail has been sent to you with your code. Please paste it here if you want to delete your account</h1>
        <vue-countdown :time="60000" v-slot="{ totalSeconds }" v-if="show">
            <div>Time remaining: {{ totalSeconds }}</div>
        </vue-countdown>
        <input class="inputbin" v-model="code"></input>
        <button class="glow-on-hover" @click="deleteAcc">Proceed</button>
    </div>
</div>
</template>

<script>
import backdrop from './backdrop.vue'
export default {
    props: ['show'],
    name: '',
    data(){
        return {
            code: ''
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },
        deleteAcc(){
            fetch('https://indertct.me/api/actuallyDelete', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({ code: this.code })})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 0){
                        localStorage.setItem('Email', '');
                        localStorage.setItem('Username', '');
                        localStorage.setItem('Password', '');
                        localStorage.setItem('cookies', '');
                        this.$router.push({name: 'signup'})
                    } else {
                        console.log(data.code, data.msg);
                    }
                })
        }
    },
    computed: {
        
    }
}
</script>

<style>

</style>