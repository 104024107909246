<template>
<body class="IsCenter">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
    <div>
        <div>
            <h1 style="font-size: 50px;">Settings</h1>
        </div>
        <h1 class="Name" style="margin-left: 5px; margin-top: -20px;" v-show="name && email">
      {{ name }} - {{ email }}
      </h1>
        <div style="margin-left: 25px;">
            <phone />
        </div>
        <div style="margin-left: 30px;">
            <div>
                <h3 style="font-size: 30px;">Binance Api Data</h3>
                <div>
                    <button @click="handlechangebin" class="glow-on-hover" style="width: 10%">Change Apikey Data</button>
                </div>
            </div>
            <div style="display: flex; margin-top: 30px;">
                <div style="display: flex; align-items: center;">
                    <div style="margin-left: 0px;">Your apikey:</div>
                    <div style="margin-left: 10px; border-radius: 0; border: 1px solid black; width: auto; padding: 5px; height: 20px;">{{ apikeyNR }}</div>
                    <button class="fa fa-eye" style="height: 32px; width: 32px;" @click="handleBlind" ref="blindbutt"></button>
                    <button class="fa fa-copy" style="height: 32px; width: 32px;" @click="copyApikey"></button>
                </div>
                <div ref="feead" style="color: green; font-weight: bold; margin-top: 8px; margin-left: 10px;"></div>
            </div>
            <div> 
                <h1 style="margin-left: -30px;">Username & Email</h1>
                <div style="margin-left: -10px; margin-top: 20px;">
                    <div class="inpbox">
                    <label for="usernamechange">Username:</label>
                    <input id="usernamechange" ref="usernameChange" class="ueinp" ></input>
                    </div>
                    <div ref="invalidusername" style="margin-top: 15px; color: red;"></div>
                    <div class="inpbox">
                    <label for="emailchange">Email:</label>
                    <input id="emailchange" ref="emailChange" class="ueinp"></input>
                    </div>
                    <div ref="invalidemail" style="margin-top: 15px; color: red;"></div>
                    <button class="glow-on-hover" @click="handleusernameemailchange" style="width: 200px; margin-top: 20px;">Change Username / E-mail</button>
                    <div ref="success" style="margin-top: 15px; color: green;"></div>
                </div>
            </div>
            <div class="dividerRL" style="margin-left: -25px; margin-top: 30px;">
                <div>
                Account valid untill: {{ VU }}
                </div>
                <div>
                Account Level: {{ AL }}
                </div>
            </div>
            <div style="margin-left: -15px; margin-top: 20px;">
                <span>You can change your password by going to the account tab </span><router-link :to="{name: 'account'}">here</router-link>
            </div>
        </div>
        <div style="margin-top: 20px;">
            <div style="margin-left: 5px; margin-top: -5px;">
                <button class="glow-on-hover" style="width: 200px; height: 50px; border-radius: 50px;" @click="affsign"> Become an affiliate today and gain 30% in all your link´s purchases </button>
            </div>
        </div>
        <div style="margin-top: 20px;">
            <div style="margin-left: 5px; margin-top: -5px;">
                <button class="glow-on-hover" @click="afflogin"> Affiliates Login </button>
            </div>
        </div>
        <!--
        <div style="margin-top: 20px;">
            <h2 style="margin-left: 15px; margin-top: 0px;">Upgrade or Renew your account</h2>
            <div style="margin-left: 5px; margin-top: -5px;">
                <button class="glow-on-hover" @click="handlecheckout"> Here! </button>
            </div>
        </div>
        -->
    </div>
    <div style="margin-top: 20px; margin-left: 10px;"><span>Note: If you want to cancel your subscription, the only way to do that is through deleting your account in the button below</span></div>
    <div style="margin-left: 5px;">
        <button class="delete-acc" @click="deleteAcc">Delete Your Account</button>
    </div>
</body>
<askPass :show="show_bin" @close="close_bin" />
<deleteAcc @close="close_dac" :show="show_dac" />
</template>

<script>
import deleteAcc from '../components/deleteAcc.vue'
import askPass from '../components/askPassphrase.vue'
import phone from './phone_.vue'

export default {
    components: { askPass, phone , deleteAcc },
    name: '',
    data(){
        return {
            show_bin: false,
            apikey: '',
            apikeyDots: '',
            apikeyNR: '',
            IsDots: false,
            email: '',
            name: '',
            VU: '',
            AL: '',
            show_dac: false
        }
    },
    mounted(){
        document.title = 'Settings';
        fetch('https://indertct.me/api/apikeyGet', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username')})})
                .then(res => res.json())
                .then(data => {
                this.apikey = data.apikey;
                this.apikeyNR = data.apikey;
                this.apikeyDots = '';

                for (var i = this.apikey.length; i > 0; i--) {
                    this.apikeyDots += '.';
                }})
                .catch(err => console.log(err))
        if(!localStorage.getItem('Email') || !localStorage.getItem('Username')){
        fetch('https://indertct.me/api/emUser', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username')})})
            .then(res => res.json())
            .then(data => {
                this.email = data.email;
                this.name = data.Name;
                this.$refs.emailChange.value = data.email;
                this.$refs.usernameChange.value = data.Name;
                localStorage.setItem('Username', this.name);
                localStorage.setItem('Email', this.email);
            })
            .catch(err => console.log(err))} else {
                this.email = localStorage.getItem('Email');
                this.name = localStorage.getItem('Username');
                this.$refs.emailChange.value = this.email;
                this.$refs.usernameChange.value = this.name;
            }
        fetch('https://indertct.me/api/dataAcc', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username')})})
            .then(res => res.json())
            .then(data => {
                this.VU = data.valid_until;
                this.AL = data.AL;
                if(!this.AL){
                this.AL = '0';
                }
            })
            .catch(err => {
                console.log(err);
            })
    },
    methods: {
        affsign(){
            this.$router.push({ name: 'affsignup' });
        },
        afflogin(){
            this.$router.push({ name: 'afflogin' });
        },
        close_dac(){
            this.show_dac = false;
        },
        deleteAcc(){
            fetch('https://indertct.me/api/sendDeleteMail', {method: 'GET',  headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}})
                .then(res => res.json())
                .then(data => {
                    if(data.code == 0){
                        this.show_dac = true;
                    } else {
                        console.log(data.code, data.msg);
                    }
                })
                .catch(err => console.log(err))
        },
        handlechangebin(){
            this.show_bin = true;
        },
        /*handlecheckout(){
            window.location.href = 'https://checkout.com';
        },*/
        close_bin(){
            this.show_bin = false;
        },
        handleBlind(){
            if(this.isDots){
                this.isDots = false;
                this.$refs.blindbutt.classList.remove("fa-eye-slash")
                this.$refs.blindbutt.classList.add("fa-eye")
                this.apikeyNR = this.apikey;
            } else {
                this.isDots = true;
                this.$refs.blindbutt.classList.remove("fa-eye")
                this.$refs.blindbutt.classList.add("fa-eye-slash")
                this.apikeyNR = this.apikeyDots;
            }
        },
        handleusernameemailchange(){
            if (this.$refs.usernameChange.value.includes("@") || !this.seeName(this.$refs.usernameChange.value)){
                this.$refs.invalidusername.innerHTML = 'Invalid Username';
                if (!this.$refs.emailChange.value.includes("@")){
                this.$refs.invalidemail.innerHTML = 'Invalid e-mail';
                } else {
                this.$refs.invalidemail.innerHTML = '';
                }
            } else if (!this.$refs.emailChange.value.includes("@")){
                this.$refs.invalidemail.innerHTML = 'Invalid e-mail';
            } else {
            this.$refs.invalidusername.innerHTML = '';
            this.$refs.invalidemail.innerHTML = '';
            fetch('https://indertct.me/api/NEpost', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: localStorage.getItem('Username'), NameNow: this.$refs.usernameChange.value, EmailNow: this.$refs.emailChange.value})})
                .then(res => res.json())
                .then(data => {
                this.$refs.success.innerHTML = 'Changed successfully'
                if(data.code == 0){
                    localStorage.setItem('Email', this.$refs.emailChange.value);
                    localStorage.setItem('Username', this.$refs.usernameChange.value);
                    this.name = this.$refs.usernameChange.value;
                    this.email = this.$refs.emailChange.value;
                } else {
                    console.log(data)
                }
                })
                .catch(err => console.log(err))
            }
        },
        copyApikey(){
            this.copyTextToClipboard(this.apikey);
            this.$refs.feead.innerHTML = 'Copied it';
        },
        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
            },
        copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
                    }
                navigator.clipboard.writeText(text).then(function() {
                console.log('Async: Copying to clipboard was successful!');
                }, function(err) {
                console.error('Async: Could not copy text: ', err);
                });
            },
        async seeName(name){
            let returns;
            await fetch('https://indertct.me/api/get_names', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Name: name})})
                .then(res => res.json())
                .then(data => returns = data.data)
                .catch(err => console.log(err))
            return returns
        }
    },
    computed: {
        
    }
}
</script>

<style>
.delete-acc{
    width: 10%;
    border-radius: 20px;
    background-color: red;
    height: 30px;
    margin-top: 30px;
}
.delete-acc:hover{
    background-color: #8B0000;
}
</style>