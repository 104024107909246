<template>
<div style="margin-top: 70px;">
    <h3>{{ name }}</h3>
    <div>
        <div style="margin-top: 5px; margin-bottom: 10px;">
            <div style="background-color: #eee; margin: auto; border-radius:10px; padding: 10px; display: inline-block;"><span style="margin-right: 20px;">{{ method }}</span> <span>{{ url }}</span></div>
        </div>
        <div class="fit-content">
            <h4>Params</h4>
            <table style="margin-top: 20px;" class="tableapi">
                <tr class="tableheaderapi">
                    <th class="tableindexapi">Name</th>
                    <th class="tableindexapi">Description</th>
                    <th class="tableindexapi">Format</th>
                </tr>
                <tr class="tablerowapi"  v-if="useapi">
                    <td class="tableDataApi">apikey</td>
                    <td class="tableDataApi">Your standard apikey. You can find it on this page and on the account page</td>
                    <td class="tableDataApi">String, on the url (ex: .../getPredCr?apikey=[your apikey])</td>
                </tr>
                <tr v-for="param in params" class="tablerowapi">
                    <td class="tableDataApi">{{ param.name }}</td>
                    <td class="tableDataApi">{{ param.desc }}</td>
                    <td class="tableDataApi">{{ param.format }}</td>
                </tr>
            </table>
        </div>
        <div>
            <div class="fit-content">
                <h4>Returns</h4>
                <table style="margin-top: 20px;" class="tableapi">
                    <tr class="tableheaderapi">
                        <th class="tableindexapi">Name</th>
                        <th class="tableindexapi">Description</th>
                        <th class="tableindexapi">Format</th>
                        <th class="tableindexapi">Example</th>
                    </tr>
                    <tr class="tablerowapi" v-if="codemsg">
                        <td class="tableDataApi">Code</td>
                        <td class="tableDataApi">A code that provides a basic description for what went wrong (code 1 - Error in authentication, code 2 - error in internal logic, code 0 - sucecss)</td>
                        <td class="tableDataApi">A string containing an integer, tipically from 0 to 2</td>
                        <td class="tableDataApi">"code": "0"</td>
                    </tr>
                    <tr class="tablerowapi" v-if="codemsg">
                        <td class="tableDataApi">msg</td>
                        <td class="tableDataApi">A message providing a verbal description of what went wrong for debugging</td>
                        <td class="tableDataApi">A string with a message, when the code is 0 the message is "success", when the code is 1 the message tells you at which step of the authentication process it failed, when the message is two it returns the error of server logic</td>
                        <td class="tableDataApi">"msg": "invalid apiey" / "msg": "success" / "msg": "Error in SQL syntax"</td>
                    </tr>
                    <tr v-for="param in returns" class="tablerowapi">
                        <td class="tableDataApi">{{ param.name }}</td>
                        <td class="tableDataApi">{{ param.desc }}</td>
                        <td class="tableDataApi">{{ param.format }}</td>
                        <td class="tableDataApi">{{ param.example }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div style="margin-top: 30px; margin-left: 10px;">
            {{ desc }}
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    props: ['name', 'url', 'method', 'params', 'desc', 'useapi', 'returns', 'codemsg'],
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>
.tableapi {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        .tableheaderapi {
            background-color: #f2f2f2;
            border-bottom: 2px solid #ddd;
        }
        .tableheaderapi th {
            padding: 10px;
            text-align: left;
        }
        .tablerowapi {
            border-bottom: 1px solid #ddd;
        }
        .tablerowapi:hover {
            background-color: #f9f9f9;
        }
        .tableDataApi {
            padding: 10px;
        }
        .tableindexapi {
            font-weight: bold;
            color: #333;
        }
        .fit-content {
            margin: auto;
            border-radius: 10px;
            padding: 10px;
            display: inline-block;
            max-width: 40%;
            word-wrap: break-word;
        }
</style>