<template>
<div style="width: 400px; height: auto; background-color: #ffcccb; border: 3px solid #8B0000; color: black; display: grid; grid-template-rows: auto auto auto; padding: 10px; font-family: Arial, sans-serif;">
    <div style="display: flex; justify-content: flex-end;" @click="deleteM">
        <i class="fa fa-close" style="cursor: pointer;"></i>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center; font-weight: bold; margin-top: 5px;">
        <span>Gravity: {{ error.gravity }}</span>
        <span>{{ error.time }}</span>
    </div>
    <div style="margin-top: 10px;">
        {{ error.error }}
    </div>
</div>
</template>

<script>
export default {
    name: '',
    data(){
        return {

        }
    },
    props: ['error'],
    methods: {
        deleteM(){
            this.$emit('delete');
        }
    },
    computed: {
        
    }
}
</script>

<style>

</style>