<template>
<div v-if="show">
    <div class="backdrop" @click.self="close">
        <div class="modal" style="width: 15%;">
            <div>
                <h1>Binance Automatic Trading</h1>
            </div>
            <div>
                <label for="ndays">For how many days?</label>
                <div>
                    <input v-model.number="ndays" id="ndays" type="number"></input>
                </div>
            </div>
            <div>
                <div>
                    <input type="radio" ref="true" :value="false" v-model="test">Paper Trading</input>
                </div>
                <div>
                    <input type="radio" ref="false" :value="true" v-model="test">Real Trading</input>
                </div>
            </div>
            <div>
                <button class="glow-on-hover" @click="handleProceed">Start Automatic Trading</button>
            </div>
            <div class="feetback" res="feed">{{ feedback }}</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    data(){
        return {
            ndays: 3,
            test: false
        }
    },
    props: ['show'],
    methods: {
        close(){
            this.$emit('close');
        },
        handleProceed(){
            console.log(this.ndays, this.test);
            fetch('https://indertct.me/api/handleTrading', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({ NDays: this.ndays, Test: this.test})})
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    if (data.code == 2){
                        console.log(data.msg);
                    } else if (data.code == 0){
                        this.$emit('success');
                        this.close();
                    }
                })
        }
    },
    computed: {
        
    }
}
</script>

<style scoped>
input[type="number"]{
    width:90%;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
button.glow-on-hover{
    margin-top: 30px;
    width: 100%
}
</style>