<template>
<body class="IsCenter">
    <div>
        <h1>Trading Signals</h1>
        <div>
            <h2>Public Signals</h2>
            <div>
                <table>
                    <thead>
                        <tr class="rowsig1">
                            <th class="index" >Crypto</th>
                            <th v-for="time in times" class="index">{{ time }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="crytptData in modelsOptim" :key="crytptData.Cr" class="rowsig" v-show="confs.includes(crytptData.Cr)">
                            <td style="font-weight: bold;">{{ crytptData.Cr }}</td>
                            <template v-for="time2 in times">
                                <td style="margin: 20px;">
                                    <div v-if="crytptData[time2].pred">
                                        {{ (crytptData[time2].pred * 100).toFixed(2) }}%
                                    </div>
                                    <div v-else style="font-weight: bold; display: grid; place-items: center;">
                                        No Data
                                    </div>
                                    <div v-if="crytptData[time2].label" style="display: flex;">
                                        <div>
                                            {{ crytptData[time2].label }}
                                        </div>
                                        <div style="margin-top: -7px;">
                                            <div :class="{ red: !crytptData[time2].buy, green: crytptData[time2].buy }" style="width: 11px; height: 11px; margin: 10px; border-radius: 50%"></div>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2>Manage your cryptos</h2>
            <div>
                <div style="margin-left: -10px;">
                    <div v-for="conf in confs" style="display: inline-block;">
                        <div class="helper" style="margin-left: 10px; margin-top: 10px; margin-right: 10px;">
                            <div class="takecr" @click="handleCrTake(conf)">{{ conf }} x </div>
                        </div>
                    </div>
                    <div>
                        <button class="glow-on-hover" style="border-radius: 20px; margin-top: 20px; width: 200px;" @click="openCrs">Add Cryptocurrency</button>
                    </div>
                </div>
            </div>
            <h2>Private Model Signals</h2>
            <div>
                <table>
                    <tr class="rowsig1">
                        <th>Crypto</th>
                        <th>Time</th>
                        <th>Certainty</th>
                        <th>Buy or Sell</th>
                    </tr>
                    <tr v-for="priv in privmodels" class="rowsig">
                        <td>{{ priv.crypto }}</td>
                        <td>{{ priv.hour }}</td>
                        <td>{{ (priv.pred * 100).toFixed(2) }}%</td>
                        <td>
                            <div style="display: flex;">
                                <div>
                                    {{ priv.label }}
                                </div>
                                <div :class="{ red: !priv.buy, green: priv.buy }" style="width: 11px; height: 11px; margin: 10px; border-radius: 50%; margin-top: 4px; padding-right: -20px;"></div>
                            </div>
                        </td>
                    </tr>
                </table>
                <div style="margin-top: 10px;">
                    <span>You can change your private models by going to the models section</span> <router-link :to="{name: 'models'}">here</router-link>
                </div>
            </div>
        </div>
    </div>
</body>
<allCr :show="showAllCr" :crlist="confs" @close="closeCrs" @new="handleNewCr"/>
</template>

<script>
import allCr from '../components/allcryptosFsignals.vue'

export default {
    name: '',
    data(){
        return {
            models: [],
            privmodels: [],
            maxfavindex: '0',
            times: [],
            modelsOptim: [],
            crypts: [],
            confs: [],
            showAllCr: false
        }
    },
    components: { allCr },
    methods: {
        handleNewCr(cry){
            this.confs.push(cry);
            this.postcsv();
        },
        handleCrTake(cr){
            this.confs = this.confs.filter(element => {
                return element != cr;
            })
            this.postcsv();
        },
        closeCrs(){
            this.showAllCr = false;
        },
        openCrs(){
            this.showAllCr = true;
        },
        postcsv(){
            var csv = '';
            this.confs.forEach(element => {
                csv += element + ',';
            })
            fetch('https://indertct.me/api/postConfig', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')},
                body: JSON.stringify({
                    Name: localStorage.getItem('Username'),
                    config: csv.slice(0, -1)
                })
            })
            .then(res => res.json())
            .then(data => {
                if(data.code == 2){
                    console.log(data.msg);
                }
            })
            .catch(err => console.log(err))
        }
    },
    computed: {
        
    },
    mounted(){
        document.title = 'Trading Signals';
        fetch('https://indertct.me/api/getModels', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')},
                body: JSON.stringify({
                    Name: localStorage.getItem('Username')
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.code == 0) {

                    this.models = data.data;
                    this.privmodels = data.privs;
                    this.times = data.times;
                    this.crypts = data.crs;
                    this.confs = data.crconf;

                    // Sort times array
                    this.times.sort((a, b) => {
                        // Extract the numeric part by removing the 'h' character and converting to number
                        let numA = parseInt(a.replace('h', ''));
                        let numB = parseInt(b.replace('h', ''));
                        // Compare the numeric values
                        return numA - numB;
                    });

                    // Find the maximum favorite index
                    this.models.forEach(element => {
                        if (element.isFav) {
                            this.maxfavindex = element.index;
                        }
                    });

                    // Initialize modelsOptim
                    this.modelsOptim = [];
                    this.crypts.forEach(element => {
                        var obj = {};
                        this.times.forEach(time => {
                            obj[time] = '';
                        });
                        obj["Cr"] = element;
                        this.modelsOptim.push(obj);
                    });

                    // Populate modelsOptim with data
                    this.models.forEach(element => {
                        var index = this.crypts.findIndex(element2 => element2 === element.crypto);
                        if (index !== -1) {
                            this.modelsOptim[index][element.hour] = {
                                pred: element.pred,
                                buy: element.buy,
                                label: element.label
                            };
                        }
                    });
                } else {
                    console.log(data.msg);
                }
            })
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
}

tr.rowsig1 {
    width: 100px;
    border: 2px solid black;
    background-color: #000000;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}

tr.rowsig {
    width: 100px;
    border: 2px solid black;
    text-align: left;
}

th, td {
    width: 100px;
    padding: 10px;
}

tr.rowsig:hover {
    background-color: #f1f1f1;
}
</style>