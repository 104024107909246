<template>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/css/intlTelInput.css">
<div v-if="phone_number">
    <div  style="margin-bottom: 20px;">
        Your phone number: {{ phone_number }}
        <button class="glow-on-hover" @click="change">Change it</button>
    </div>
</div>
<div v-else style="margin-bottom: 30px;">
    <h3>Add Phone Number</h3>
    <vue-tel-input ref="telinput" style="border-radius: 10px; width: 30%;" v-model="phone_n" mode="international" @validate="validatePhone"></vue-tel-input>
    <div><button class="glow-on-hover" @click="postphone">Add Phone Number</button></div>
    <div ref="error" style="color: red; margin: 10px;">{{ error_msg }}</div>
</div>
</template>

<script>

export default {
    name: '',
    data(){
        return {
            phone_number: '',
            phone_n: '',
            input: '',
            error_msg: '',
            possible: false,
            valid: false
        }
    },
    methods: {
        change(){
            this.phone_number = '';
        },
        validatePhone(res){
            this.possible = !!res.possible;
            this.valid = !!res.valid;
        },
        postphone(){
            if (this.valid && this.possible){
            fetch('https://indertct.me/api/phone_number', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({phone: this.phone_n})})
                .then(res => res.json())
                .then(data => {
                if (data.code == 0){
                    this.phone_number = this.phone_n;
                } else if (data.code == 1 || data.code == 2){
                    console.log(data.msg);
                }
                })
            } else {
                this.$refs.error.innerHTML = '<strong>Invalid Phone Number</strong>';
            }
        }
    },
    computed: {
    },
    mounted(){
        fetch('https://indertct.me/api/phone_number', {method: 'GET', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}})
            .then(res => res.json())
            .then(data => {
            if (data.code == 0){
                this.phone_number = data.data;
                if(!this.phone_number){
                }
            } else if (data.code == 1 || data.code == 2){
                console.log(data.msg);
            }
            })
    }
}
</script>

<style>
</style>