<template>
<div v-if="show">
    <div class="backdrop" @click.self="close">
        <div class="modal">
            <label id="crs" for="cn12345">
                Cryptocurrency Symbol
            </label>
            <input type="text" id="cn12345" placeholder="BTC" v-model="cryptoSigla">
            </input>
            <label id="crn" for="cn123">
                Cryptocurrency Name
            </label>
            <input type="text" id="cn123" placeholder="Bitcoin" v-model="cryptoFullName">
            </input>
            <button @click="submitApplication" class="glow-on-hover">Request</button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'requestCr',
    props: ['show'],
    data(){
        return {
            cryptoFullName: '',
            cryptoSigla: ''
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },
        submitApplication(){
            fetch('https://indertct.me/api/propose', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({ CrName: this.cryptoFullName, CrSymbol: this.cryptoSigla})})
                .then(res => res.json())
                .catch(err => console.log(err))
            this.close();                
        }
    }
}
</script>

<style scoped>
.backdrop{
        top: 0;
        position: fixed;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
}

.modal{
        width: 130px;
        padding: 20px;
        margin: 100px auto;
        background: white;
        border-radius: 30px;
}

input{
    height: 30px;
    width: 120px;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    align: left;
}

label{
    font-weight: bold;
    margin-bottom: 10px;
    align: left;
}

.glow-on-hover {
        width: 120px;
        margin-left: 5px;
        margin-top: 10px;
        height: 30px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 20%;
        align: center;
    }
    
    .glow-on-hover:before {
        content: '';
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;
    }
    
    .glow-on-hover:active {
        color: #000
    }

    @keyframes glowing {
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }
    
    .glow-on-hover:active:after {
        background: transparent;
    }
    
    .glow-on-hover:hover:before {
        opacity: 1;
    }
    
    .glow-on-hover:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        left: 0;
        top: 0;
        border-radius: 10px;
    }

</style>