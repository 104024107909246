<template>
<div class="backdrop">
    <div class="modal" style="width: auto; height: auto;">
        <div class="spinner">

        </div>
        <div style="margin-top: 10px;">
        Loading...
        </div>
    </div>
</div>>
</template>

<script>
export default {
    name: '',
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    }
}
</script>

<style>
.spinner {
    border: 6px solid #e0e0e0; /* Light border */
    border-top: 6px solid #3498db; /* Blue top border */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}
</style>