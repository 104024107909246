import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home.vue'
import account from '../views/account.vue'
import support from '../views/supportView.vue'
import models from '../views/models.vue'
import signals from '../views/signalsview.vue'
import okx from '../views/okx.vue'
import api from '../views/api.vue'
import settings from '../views/settings.vue'
import newhere from '../views/newHere.vue'
import signup from '../views/signup.vue'
import login from '../views/login.vue'
import apidocumentation from '../components/apiDocumentation.vue'
import notfound from '../components/404notfound.vue'
import affiliates from '../views/affiliates.vue'
import affsignup from '../views/affsignup.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { auth: true }
  },
  {
    path: '/account',
    name: 'account',
    component: account,
    meta: { auth: true }
  },
  {
    path: '/support',
    name: 'support',
    component: support,
    meta: { auth: true }
  },
  {
    path: '/models',
    name: 'models',
    component: models,
    meta: { auth: true }
  },
  {
    path: '/signals',
    name: 'signals',
    component: signals,
    meta: { auth: true }
  },
  {
    path: '/binance-integration',
    name: 'okx',
    component: okx,
    meta: { auth: true }
  },
  {
    path: '/api',
    name: 'api',
    component: api,
    meta: { auth: true }
  },
  {
    path: '/settings',
    name: 'settings',
    component: settings,
    meta: { auth: true }
  },
  {
    path: '/newhere',
    name: 'newHere',
    component: newhere,
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: { auth: false, showthanks: false }
  },
  {
    path: '/signup',
    name: 'signup',
    component: signup,
    meta: { auth: false }
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: apidocumentation,
    meta: { auth: false }
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: notfound,
    meta: { auth: false }
  },
  {
    path: '/thanks',
    name: 'login2',
    component: login,
    meta: { auth: false, showthanks: true }
  },
  {
    path: '/affiliates',
    name: 'afflogin',
    component: affiliates,
    meta: { auth: false, aff: true }
  },
  {
    path: '/affsignup',
    name: 'affsignup',
    component: affsignup,
    meta: { auth: false, aff:true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('Password') && !!localStorage.getItem('Username') && !!localStorage.getItem('logged');
  if (to.meta.auth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router
