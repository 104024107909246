<template>
<body class="IsCenter">
    <div>
        <h1 style="margin-left: -15px">API Documentation</h1>
        <div>
        <div style="margin: 10px;">Note:</div>
        <div style="margin: 10px;">We have a python module for you to work with our API more easily<a href="https://pypi.org/project/IndertCT/0.0.7/" target="_blank" style="margin-left:4px;">here</a></div>
        <div style="margin: 10px;">GET params are meant to be passed in the url (ex.: https://indertct.me/api/getPredCrypt?apikey=[your apikey])</div>
        <div style="margin: 10px;">POST params are meant to be passed in the request body in JSON format</div>
        <div style="margin: 10px;">Base url is https://indertct.me/</div>
        <div style="margin-top: -30px;">
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/getPredCrypt" method="GET" :params="crParams" :returns="crReturns" desc="Use this api endpoint if you want to access all available predictions for a specific cryptocurrency" name="Get Prediction Based On Cryptocurrency" />
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/getPredCrHour" method="GET" :params="crhParams" :returns="crhReturns" desc="Use this api endpoint to get the prediction based on cryptocurrencie(s) and time(s)" name="Get Predidction Based On Cryptocurrency and Time" />
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/postPredNames" method="Post" :params="crnparams" :returns="crnreturns" desc="Use this api endpoint to get the market forecast for specific models, using the model name that can be found in the models tab and in this API" name="Get Prediction Based On Model Name"/>
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/modelNames" method="GET" :params="p11" :returns="p12" name="Get All Models" desc="You can use this api endpoint to get all models and it´s properties (private and public)"/>
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/StartTrading" method="POST" :params="p21" :returns="p22" desc="You can start automatic trading, with your current trading configurations, that you can change in this api or in the binance integration tab" name="Start Automatic Binance Trading" />
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/StopTrading" method="POST" :params="p31" :returns="p32" desc="You can use this endpoint to stop binance automatic AI trading" name="Stop Binance Automatic AI Trading"/>
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/getTickersApi" method="GET" :params="p41" :returns="p42" desc="An endpoint you can use to get all available automatic trading signals for manual trading" name="Get All Tickers"/>
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/manualTrade" method="POST" :params="p51" :returns="p52" desc="You can use this endpoint if you want to do manual trading" name="Manual Trading"/>
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/historicalCr" method="POST" :params="p61" :returns="p62" desc="You can use this endpoint to get historical cryptocurrency data from our database" name="Historical Cryptocurrency Data"/>
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/handlePrivs" method="POST" :params="p71" :returns="p72" desc="You can use this endpoint to take, add or see you private models" name="Handle Private Models"/>
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/handleFavPubs" method="POST" :params="p81" :returns="p82" desc="You can use this endpoint to manage your favorite public models" name="Handle Favorite Public Models"/>
            </div>
            <div>
                <BAD :codemsg="true" :useapi="true" url="/api/handleTradingVariables" method="POST" :params="p91" :returns="p92" desc="You can use this endpoint to see, change and manage your variables for Automatic Binance Trading" name="Manage Automatic Trading Variables"/>
            </div>
        </div>
    </div>
    </div>
</body>
</template>

<script>
import BAD from './BasicApikeyDesc.vue'

export default {
    components: { BAD },
    name: '',
    beforeCreate(){
        document.title = 'Documentation';
    },
    data(){
        return {
            apikeyNR: '',
            showGoogle: false,
            p91: [
                {
                    name: 'password',
                    desc: 'Your account´s password, if you´re a normal account, if not the apikey password you can get at the top of this page. It´s required for us to validate your identity',
                    format: 'string'
                },
                {
                    name: 'data',
                    desc: 'What you want the program to do. Is a list of objects taht contain at least 2 keys: "name" and "method". "method" can be "see", "change" or "take" and "add" (these last two only applicable if the name of the variable is modelsPrivate or models_trade, the last 3 methods require a data property inside the same object). The name key can be "models_trade", which handles the public models you want to do automatic trading with and supports 3 methods (which you´ll pass in the method key): "see", which returns a list with the name of all the models you currently have as tradable in automatic trading, "take", which takes out the models you pass on the data property and "add", which adds the models you pass in the data property. Same thing for the "modelsPrivate" name, which is the private models you want to do your automatic trading with. With the "Stablecoin" (your quote coin in automatic trading), "lossTh" (loss threshold, how many money, in USD, are you willing to lose in one day before the program stops), "percentage" (if a model finds a good trade, what´s the percentage of the portfolio you want to go to that trade), "Apikey" (your binance Apikey) and "SecretKey" (your binance secretkey), "model_exposure" (the max exposure you want to have in a model, in % of your portfolio, this array and the models_trade arrray are linked) and "model_exposure_priv" (same thing than with model_exposure name, but with tradable private models) you have two methods: "change" and "see". "see" makes it return its current value in the end. "change" changes it, based on your data property',
                    format: 'The data property in you objects inside the data param in the main JSON body should only be used if method is set to "change", "take" or "add". I already explained the format of this property in the "models_trade" and "modelsPrivate" names in the description of this param. In the "Stablecoin" param, if method set to "change", the data property should be a string containing an available quote coin for binance automatic trading. If you insert an invalid coin, it will be returned to you an array with all the currenct available coins. The data property in the "percentage" name should be a float representing the max percentage of your portfolio a trade can have. With the "lossTh" name, the data property should be a float or integer representing the threshold for the maximum trading loss in a day to shut down the program. In the "Apikey" and "SecretKey" names, the data property should be a string containing your Binance Apikey and your Binance SecretKey, respectivly. In the "model_exposure" and "model_exposure_priv" names, your data property should be an array of objects with two keys: "index" and "new_value", which will change the index of the exposures of the models (you can check your array by getting that data from a "see" method) based on the index of the current model_exposure (or model_exposure_priv) array adn the new_value that you sent us. The "index" key should be an integer and the "new_value" key should be a float. Remenber that the model_exposure variable is linked with the models_trade variable and the model_exposure_priv variable with the modelsPrivate variable'
                }
            ],
            p92: [
                {
                    name: 'data',
                    desc: 'An object containing the values of all the variables in which you set the method to "see"',
                    format: 'An object containing those values that you asked for, each key is the name of the variable that you´ve asked for',
                    example: '{"code": "0","msg": "success","data": {"percentage": 10.0,"lossTh": 100000.0,"models_trade": ["ADA12h.keras", "ADA48h.keras", "ADA60h.keras", "SOL24h.keras", "SOL36h.keras", "SOL72h.keras", "ETH12h.keras", "WLD12h.keras", "DOGE24h.keras", "DAI24h.keras"} }'
                }
            ],
            p81: [
                {
                    name: 'password',
                    desc: 'Your account´s password, if you´re a normal account, if not the apikey password you can get at the top of this page. It´s required for us to validate your identity',
                    format: 'string'
                },
                {
                    name: 'method',
                    desc: 'Which method you want to use in the api endpoint. There´s 3 of them: "see", "take" and "add", each one does what it looks like it does',
                    format: 'string'
                },
                {
                    name: 'models',
                    desc: 'Necessary in the "add" and "take" methods, if method set to "add" adds those model, if model set to "take" takes those models',
                    format: 'An array of values conatining the name of the model, which you can find in the models tab in this same web application Ex.: ["ADA12h.keras", "SOL108h.keras", ...]'
                }
            ],
            p82: [
                {
                    name: 'data',
                    desc: 'Only is returned if method is set to "see", has all the names of your current private models',
                    format: 'An array with all the names of your private models',
                    example: '"data": ["ADA12h.keras", "SOL96h.keras", ...]'
                }
            ],
            p71: [
                {
                    name: 'password',
                    desc: 'Your account´s password, if you´re a normal account, if not the apikey password you can get at the top of this page. It´s required for us to validate your identity',
                    format: 'string'
                },
                {
                    name: 'method',
                    desc: 'Which method you want to use in the api endpoint. There´s 3 of them: "see", "take" and "add", each one does what it looks like it does',
                    format: 'string'
                },
                {
                    name: 'models',
                    desc: 'Necessary in the "add" and "take" methods, if method set to "add" adds those model, if model set to "take" takes those model',
                    format: 'An array of values conatining the name of the model, which you can find in the models tab in this same web application Ex.: ["ADA12h.keras", "SOL108h.keras", ...]'
                }
            ],
            p72: [
                {
                    name: 'data',
                    desc: 'Only is returned if method is set to "see", has all the names of your current private models',
                    format: 'An array with all the names of your private models',
                    example: '"data": ["ADA12h.keras", "SOL921h.pt", ...]'
                }
            ],
            p61: [
                {
                    name: 'times',
                    desc: 'The times that you want the data from',
                    format: 'JSON object containing two keys: "beginning" and "end", which contain the unix timestamps for the begginig and the end of the data you want to recieve. Unix timestamps must be either integer or floats'
                },
                {
                    name: 'crs',
                    desc: 'The cryptocurrencies you want to get data from',
                    format: 'Anb array containing the symbols of the cryptocurrencies you want data from. "BTC", not "Bitcoin"'
                },
                {
                    name: 'limit',
                    desc: 'The threshold, in seconds, you want to get data. Ex.: if a certain data entry is 1000 seconds after your end key in the "times" param, then if your limit is equal or higher than 1000, that piece of data will be included. Not required',
                    format: 'integer or float'
                },
                {
                    name: 'custom data',
                    desc: 'What parts of the data you want to get. If you want to get it all just don´t add this parameter in you json body',
                    format: 'An object that must contain 7 keys: "Price", "RSI", "Google trends", "Price diff", "Volume", "Market cap" and "tvl". All of those keys must point to a boolean value. If false, that part of the data will not be returned (for all elements in the data array), if true it will be returned (for all elements in the data array)'
                },
                {
                    name: 'password',
                    desc: 'Your account´s password, if you´re a normal account, if not the apikey password you can get at the top of this page. It´s required for us to validate your identity',
                    format: 'string'
                }
            ],
            p62: [
                {
                    name: 'data',
                    desc: 'The data you´ve asked for. Can contain RSI data, the price of the cryptocurrency (in USD) at the time, the price difference ( times a thousand ) between 15 minutes for a day, the volume, the market cap ( divided by 10 thousand ), the tvl / market cap and 5 days of google trends data for that cryptocurrency',
                    format: 'An object that contains Price, time (in unix timestamp), Price diff, Volume, RSI, Google trends, tvl, Market cap and cryptocurrency keys, each containing what they represent linguistically',
                    example: '"data": [{"time": 1719906561.390573, "Price": 80123.1234, "Price diff": [-159500.0, -227215.0 136155.0, 51550.0, ...], "RSI": [...], "Google trends": [97, 88, 80, 60, 40], "Volume": [8537.88, 1622.831, ...], "Market cap": 1375357.92477, "tvl": 0.0, "cryptocurrency": "BTC"}, {...}, ...]'
                }
            ],
            p51: [
                {
                    name: 'password',
                    desc: 'Your account´s password, if you´re a normal account, if not the apikey password you can get at the top of this page. It´s required for us to validate your identity',
                    format: 'string'
                },
                {
                    name: 'symbol',
                    desc: 'The symbol of the exchange you want to trade in, you canm check all symbols in the endpoint above',
                    format: 'string (base coin + quote coin)'
                },
                {
                    name: 'quantity',
                    desc: 'How much you want ot buy/sell in that exchange',
                    format: 'float (floating point number)'
                },
                                {
                    name: 'buy',
                    desc: 'If you want to buy or sell',
                    format: 'boolean value. True if you want to buy and false if you want to sell'
                }
            ],
            p52: [
                {
                    name: 'error',
                    desc: 'may not show. It tells you that there´s an error and tries its best to tell you what it is',
                    format: 'string',
                    example: '"No binance apikey or secret key"'
                }
            ],
            p41: [],
            p42: [
                {
                    name: 'data',
                    desc: 'The tickers and symbols of the available cryptocurrencies for binance trading',
                    format: 'JSON object, has three keys: "symbol", which contains a list with the symbols (base + quote), "base", which contains a list of the base coin for each specific symbol and "quote", which contains a list of the quote coin for each specific symbol',
                    example: '"data": {"base": ["ETH", "BTC", ...], "quote": ["BTC", "EUR", ...], "symbol": ["ETHBTC", "BTCEUR", ...]}'
                }
            ],
            p31: [
                {
                    name: 'password',
                    desc: 'Your account´s password, if you´re a normal account, if not the apikey password you can get at the top of this page. It´s required for us to validate your identity',
                    format: 'string'
                }
            ],
            p32: [],
            p21: [
                {
                    name: 'password',
                    desc: 'Your account´s password, if you´re a normal account, if not the apikey password you can get at the top of this page. It´s required for us to validate your identity',
                    format: 'string'
                },
                {
                    name: 'ndays',
                    desc: 'number of days you want to be trading for. You can always stop trading if you want',
                    format: 'integer'
                },
                {
                    name: 'test',
                    desc: 'If your trading is a test or not. If it´s a test, binance testnet will be activated',
                    format: 'boolena value - true if you want to test and false if not'
                }
                ],
            p22: [],
            p11: [],
            p12: [
                {
                    name: 'data',
                    desc: 'The data you´ve asked for',
                    format: 'A JSON object containing two keys: "modelsPriv" and "modelsPub", each one of those is a list having all the data of the models, in JSON',
                    example: '[{"name": "LUNA84h.keras", "time": "84h", "type": ".keras", "cryptocurrency": "LUNA", "accuracy": 0.8972123 # -> 89.7% }]'
                }
            ],
            p: [],
            apikey: '',
            apikeyDots: '',
            IsDots: false,
            crParams: [
                {
                    name: 'crs', 
                    desc: 'A csv of the cryptocurrencies your want to get predictions from', 
                    format: 'csv (comma separated value), ex.: .../api/getPredCrypt?apikey=[your apikey]&crs=ADA,ETH,BTC,SOL'
                }
                    ],
            crReturns: [
                {
                    name: 'data', 
                    desc: 'A JSON object containing indexes for all the cryptos you´ve asked for, each one having an array of object which have the different times and predictions for those times', 
                    format: 'Each element of the array for each cryptocurrency folows has the same properties: "time", "buy" and "accuracy", time means that the model was trained to predict movements in price in that time, for example 12h means 12 hours. It´s always in hours. "buy" is a boolean value. If true, you should buy, if not, you should sell. "accuracy" is how certain the model is about it´s prediction. Please note that if "buy" is false it´s how certain it is that you should sell that cryptocurrency and if it´s true it´s how certain it is you should buy it',
                    example: '"ADA": [{ "time": "12h", "buy": false, "accuracy": 0.78122314151 }...], "BTC": [...] ...'
                }
                    ],
            crhParams: [
                {
                    name: 'time',
                    desc: 'A csv of the times you want to get data from. You can find all available times and cryptos in another of our api endpoints',
                    format: 'String, always in hours and with a h at the end. Ex.: .../api/getPredCrHour?apikey=[your apikey]&time=12h,24h,96h'
                },
                {
                    name: 'crs', 
                    desc: 'A csv of the cryptocurrencies your want to get predictions from', 
                    format: 'csv (comma separated value), ex.: .../api/getPredCrHour?apikey=[your apikey]&crs=ADA,ETH,BTC,SOL'
                }
                    ],
            crhReturns: [
                {
                    name: 'data',
                    desc: 'The data you asked for',
                    format: 'If you haven´t specified the times, we´ll just return the cryptos you´ve asked for all available times, if you haven´t specified the cryptocurrences we´ll give you the times for all available cryptocurrencies, if you haven´t specified anything we´ll give you all available times and cryptos. The format is the same as in the GET /api/getPredCrypt endpoint',
                    example: '"ADA": [{ "time": "12h", "buy": false, "accuracy": 0.78122314151 }...], "BTC": [...] ...'
                }
                    ],
            crnparams: [
                {
                    name: 'models',
                    desc: 'This parameter is necessary for us to know which models you want to get data from. The model name follows always the same structure (you can get a list of all models in this API): cryptocurrency + time + type (ex.: SOL108h.keras)',
                    format: 'A list containing objects (dictionaries, hashmaps) which have two keys: name and isPriv, name is a string with the name of the model and isPriv is a boolean value (true if it´s a private model and false if it´s a public model). Ex.: "models": [{"name": "ADA36h.keras", "isPriv": false}, ...]'
                }
                    ],
            crnreturns: [
                    {
                        name: 'data',
                        desc: 'The data you´ve asked for',
                        format: 'A hashmap which keys are the model names you´ve asked for. Each one of those name has another JSON object which has "time", "cryptocurrency", "pred" and "buy" keys, just like in the other get-data endpoints',
                        example: '"data": {"ADA36h.keras": {"time": "36h", "cryptocurrency": "ADA", "pred": 0.8767162351523, "buy": true}, {"SOL120h.keras": {...}} ...}'
                    }
                ],
            isGoogle: false
        }
    }
}
</script>

<style>

</style>